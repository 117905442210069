import React, { FC, ReactNode } from 'react';

import useStore from '@/store';

import { AiFillCheckCircle, AiOutlineClose } from 'react-icons/ai';

import {
  ContentModal,
  Header,
  PopupActions,
  PopupContainer,
  PopupContent,
  PopupDescription,
  PopupTitle,
} from './styles';

export interface PopupProps {
  open?: boolean;
  onClose?: (show: boolean) => void;
  title?: string;
  description?: string;
  isConfirm?: boolean;
  onAction?: () => void;
  children?: ReactNode;
}

export const Popup: FC<PopupProps> = ({
  open,
  onClose,
  title,
  description,
  isConfirm = false,
  onAction,
  children,
}) => {
  const { updateGetList } = useStore(state => state);

  const handleClose = () => onClose && onClose(false);

  const handleAction = () => {
    if (onAction) {
      onAction();
      onClose && onClose(false);

      if (isConfirm) updateGetList(false);
    }
  };

  if (!open) {
    return null;
  }

  return (
    <PopupContainer onClick={handleClose}>
      <ContentModal>
        <Header>
          <AiOutlineClose
            size={24}
            color="#818181"
            className="close"
            onClick={handleClose}
          />
        </Header>
        <PopupContent onClick={e => e?.stopPropagation()}>
          {title && <PopupTitle>{title}</PopupTitle>}
          {description && <PopupDescription>{description}</PopupDescription>}
          {!children ? (
            <PopupActions>
              {isConfirm ? (
                <AiFillCheckCircle
                  size={40}
                  onClick={handleAction}
                  color="#D8F32E"
                />
              ) : (
                <button
                  className="bg-[#333333aa] px-2 text-white rounded-[50px]"
                  onClick={handleAction}>
                  Cancelar
                </button>
              )}
            </PopupActions>
          ) : (
            children
          )}
        </PopupContent>
      </ContentModal>
    </PopupContainer>
  );
};

export default Popup;
