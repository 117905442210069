import styled from 'styled-components';

interface IconProps {
  bgColor?: string;
}

export const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  @media screen and (max-width: 400px) {
    padding: 20px;
  }
`;

export const ContentModal = styled.div`
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  padding: 16px;
  width: 400px;
  border: 7px solid rgba(255, 255, 255, 0.3);
  box-shadow: 4px 4px 44px rgba(0, 0, 0, 0.06);
  position: relative;
`;

export const Tag = styled.div`
  height: 39px;
  background: rgba(214, 214, 214, 0.2);
  border-radius: 500px;
  width: 138px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #949494;

  span {
    font-family: 'Graphik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #949494;
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DescriptionText = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #838383;
  margin: 16px 0;
  max-width: 400px;
  width: 100%;
`;

export const NameText = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16.5px;
  color: #838383;
  max-width: 400px;
  width: 100%;
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
  position: relative;
  margin-top: 30px;
`;

export const Title = styled.p`
  text-align: left;
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 21px;
  color: #434343;
`;

export const IconsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const Icon = styled.div<IconProps>`
  background-color: ${({ bgColor }) => bgColor};
  width: 41px;
  height: 41px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonClose = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
`;
