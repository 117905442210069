export const Errors = () => {
  return {
    INVALID_VERSION: 'Esta versão do aplicativo não é válida',

    UNAUTHORIZED: 'E-mail ou senha são inválidos',

    CHARGE_NOT_FOUND: 'Cobrança não encontrada',
    CHARGE_NOT_PAID: 'Cobrança não paga',
    CHARGE_FAILED_TO_REFUND: 'Falha ao reembolsar a cobrança',

    CHECKIN_NOT_FOUND: 'Check-in não encontrado',
    CHECKIN_INVALID_STATUS: 'Check-in tem status inválido para este processo',
    CHECKIN_WITHOUT_TRANSACION: 'Este check-in não tem transação válida',
    CHECKIN_ALREADY_REVIWED: 'O check-in já foi revisado',
    CHECKIN_EXCEED_ACTIVE_DAILY_QUOTA:
      'Excedeu a cota diária de checkins ativos',

    CITY_NOT_FOUND: 'Cidade não foi encontrada',

    INVALID_CONFIRMATION_CODE: 'Código de confirmação inválido',

    INCORRECT_KEY: 'Chave incorreta',

    COUPON_NOT_FOUND: 'Cupom não encontrado',
    COUPON_INVALID_EARLY_USE: 'O cupom ainda não é válido',
    COUPON_EXPIRED: 'Cupom expirou',
    COUPON_NOT_ACTIVE: 'Cupom não está ativo',
    COUPON_ALREADY_USED: 'Cupom já utilizado',
    COUPON_SINGLE_USE_USED: 'Cupom já utilizado e de uso único',
    COUPON_MAX_USES: 'Cupom já usado no máximo de vezes',
    COUPON_INVALID_TYPE: 'O tipo de cupom é inválido',

    CREDIT_CARD_NOT_FOUND: 'Cartão não encontrado',
    CREDIT_CARD_UNAUTHORIZED: 'Cartão de crédito não autorizado',
    CREDIT_CHANGE_INVALID_TYPE: 'Esta alteração de crédito tem tipo inválido',

    CREDIT_NOT_FOUND: 'Crédito não encontrado',
    CREDIT_NOT_ENOUGH: 'Crédito insuficiente',
    CREDIT_USED: 'Crédito já foi utilizado',

    CRM_ERROR_ON_PERSIST_USER: 'Usuário não persistido',
    CRM_ERROR_ON_SET_USER: 'Não podemos definir o usuário no crm',
    CRM_ERROR_ON_SET_CLIENT: 'Não podemos configurar o cliente no CRM',
    CRM_PERSON_NOT_FOUND: 'CRM não encontrado',
    CRM_PERSON_DEAL_NOT_FOUND: 'Negócio de pessoa de crm não encontrado',

    DATE_INCONSISTENCY: 'As datas fornecidas são inconsistentes',

    DEBIT_NOTE_NOT_FOUND: 'Nota de débito não encontrada',

    DTO_INVALID: 'Este DTO é inválido',

    ESTABLISHMENT_NOT_FOUND: 'Estabelecimento não encontrado',
    ESTABLISHMENT_DO_NOT_HAVE_OPENING_HOURS:
      'Estabelecimento não possui horário de abertura',
    ESTABLISHMENT_NOT_PUBLISHED: 'Estabelecimento não publicado',
    ESTABLISHMENT_DO_NOT_HAVE_PHONE: 'Estabelecimento não tem telefone',
    ESTABLISHMENT_DO_NOT_HAVE_NEIGHBORHOOD: 'Estabelecimento não tem bairro',
    ESTABLISHMENT_DO_NOT_HAVE_PHOTOS: 'Estabelecimento não tem fotos',
    ESTABLISHMENT_DO_NOT_HAVE_MODALITIES: 'Estabelecimento não tem modalidades',
    ESTABLISHMENT_ID_NOT_FOUND: 'Id do estabelecimento não foi encontrado',
    ESTABLISHMENT_DEBIT_NOTE_NOT_FOUND:
      'Nota de débito do estabelecimento não foi encontrada',
    ESTABLISHMENT_GROUP_NOT_FOUND: 'Grupo de estabelecimentos não encontrado',
    ESTABLISHMENT_PRODUCT_GROUP_NOT_FOUND:
      'Grupo de produtos do estabelecimento não encontrado',
    ESTABLISHMENT_PRODUCT_GROUP_NAME_DUPLICATED:
      'O estabelecimento já possui um grupo de produtos com este nome',
    ESTABLISHMENT_WORKER_NOT_FOUND:
      'Funcionário do estabelecimento não encontrado',

    EXTERNAL_INFORMATION_NOT_FOUND: 'Informações externas não encontradas',

    FORBIDDEN_RESOURCE: 'Recurso proibido',

    INTERNAL_SERVER_ERROR:
      'Problemas com o servidor, tente novamente mais tarde',

    INVOICE_PAID: 'Esta fatura já foi paga',
    INVOICE_INVALID_VALUE: 'Valor inválido da fatura',
    INVOICE_INVALID_STATUS: 'Status inválido da fatura',

    ITEM_NOT_FOUND: 'Item não foi encontrado',

    MODALITY_NAME_DUPLICITY: 'Já tem modalidade com este nome',

    NEIGHBORHOOD_NAME_DUPLICATED:
      'Já tem bairro com este nome cadastrado nesta cidade',

    ORDER_NOT_FOUND: 'Pedido não encontrado',
    ORDER_INVALID_SUBSCRIPTION: 'Pedido não possui assinatura',
    ORDER_INVALID_STATUS: 'Pedido com status inválido para esta operação',
    ORDER_INVALID_INVOICE: 'Pedido não possui fatura',
    ORDER_INVALID_TYPE: 'Pedido tem tipo inválido para esta operação',

    INVALID_ORDER_ITEM_TYPE: 'Tipo de item de pedido inválido',
    ORDER_ITEM_WITHOUT_PLAN_ID: 'Este item do pedido não tem plano',
    ORDER_ITEM_WITHOUT_ITEM_ID: 'Este item do pedido não possui item',
    ORDER_ITEM_INVALID_TRANSACTION: 'Este item do pedido não possui transação',
    ORDER_ITEM_NOT_FOUND: 'Item do pedido não encontrado',

    PAYMENT_METHOD_NOT_ESPECIFIED: 'Nenhum método de pagamento especificado',
    INVALID_PAYMENT_METHOD: 'A forma de pagamento especificada é inválida',

    PAYMENT_VOUCHER_DEBIT_NOTE_INVALID_STATUS:
      'A nota de débito deste comprovante de pagamento tem status inválido',
    PAYMENT_VOUCHER_NOT_FOUND: 'Comprovante de pagamento não encontrado',

    PLAN_NOT_HAVE_CREDITS: 'Este plano não tem créditos',
    PLAN_INVALID: 'Plano inválido',
    PLAN_EXCEEDS_COVERAGE: 'A cobertura do plano foi excedida',
    PLAN_NOT_FOUND: 'Plano não encontrado',
    PLAN_NOT_RENEWABLE: 'Este plano não pode ser renovável',
    PLAN_INVALID_TYPE: 'Este tipo de plano é inválido',
    PLAN_IS_NOT_FOR_SUBSCRIPTION: 'Este plano não é um plano de assinatura',
    PLAN_INCONSISTENCY:
      'Estes dados do plano são inconsistências com os dados esperados',
    PRODUCT_NOT_FOUND: 'Produto não encontrado',
    PRODUCT_HAVE_ACTIVE_PROMOTION: 'Produto tem promoção ativa',
    PRODUCT_NOT_BELONGS_TO_ESTABLISHMENT:
      'Produto não pertence ao estabelecimento',
    PRODUCT_ALREADY_BELONGS_TO_ANOTHER_PRODUCT_GROUP:
      'Produto já pertence a outro grupo de produtos',

    INVALID_PRODUCT_HOUR: 'Horário do produto inválido',

    PROMOTION_PRODUCT_NOT_FOUND: 'Produto de promoção não encontrado',
    PROMOTION_ALREADY_ACTIVE: 'Esta promoção já está ativa',
    PROMOTION_ALREADY_DEACTIVE: 'Esta promoção já está desativada',

    PASSWORDS_NOT_MATCH: 'As senhas não coincidem',

    GURU_CUSTOMER_INVALID_DATA:
      'Necessita de mais dados para verificar o cliente Seu Guru',

    STATE_NOT_FOUND: 'Estado não encontrado',

    SUBSCRIPTION_NOT_FOUND: 'Assinatura não encontrada',
    INVALID_SUBSCRIPTION_STATUS:
      'Status de assinatura inválido para esta operação',
    SUBSCRIPTION_IS_NOT_ACTIVE: 'A assinatura não está ativa',
    SUBSCRIPTION_CHANGE_IS_FOR_THE_SAME_PLAN:
      'A mudança de assinatura é para o mesmo plano',
    SUBSCRIPTION_TYPE_IS_NOT_SAME_AS_PLAN:
      'O tipo de assinatura não é o mesmo que o plano',
    SUBSCRIPTION_INVALID_DATES: 'Esta assinatura tem datas incorretas',
    SUBSCRIPTION_NOT_HAVE_NEXT_RENEW:
      'Esta assinatura não tem próxima renovação',

    TRANSACTION_NOT_FOUND: 'Transação não encontrada',
    TRANSACTION_INVALID: 'Esta transação é inválida',

    USER_NOT_FOUND: 'Usuário não encontrado',
    USER_NOT_HAVE_CREDIT_CARD: 'Este usuário não possui cartão de crédito',
    USER_INVALID_PUSH_TOKEN: 'Este usuário possui um token push inválido',
    USER_EMAIL_ALREADY_REGISTRED:
      'Este e-mail já está registrado para o usuário',
    USER_PHONE_ALREADY_REGISTRED:
      'Este telefone já está registrado para o usuário',
    USER_CPF_ALREADY_REGISTRED: 'Este CPF já está registrado para o usuário',

    WALLET_NOT_FOUND: 'Carteira não encontrada',

    WEB_RESOURCE_INVALID_TYPE: 'Tipo de recurso da web inválido',

    WEEK_DAY_NOT_FOUND: 'Dia da semana não encontrado',

    FIELD_EMPTY: 'O campo não pode estar vazio',
    FIELD_MUST_BE_EMAIL: 'O campo deve ser um e-mail válido',
    FIELD_MUST_BE_STRING: 'O campo deve ser uma string',
    FIELD_MUST_BE_BOOLEAN: 'O campo deve ser um booleano',
    FIELD_MUST_BE_DATESTRING: 'O campo deve ser uma string de data',
    FIELD_MUST_BE_OBJECT: 'O campo deve ser um objeto',
    FIELD_MUST_BE_UUID: 'O campo deve ser um UUID',
    FIELD_MUST_BE_INT: 'O campo deve ser um número inteiro',
    FIELD_NUMBER_IS_LOWER_THAN_MINIMUM:
      'O número do campo é menor do que o mínimo',
    FIELD_NUMBER_IS_GREATER_THAN_MAXIMUM:
      'O número do campo é maior do que o máximo',
    FIELD_INVALID_VALUE_FOR_ENUM:
      'O valor do campo não é válido para as opções do enum',
    FIELD_LENGTH_IS_GREATER_THAN_MAX:
      'O comprimento do campo é maior do que o máximo',
    FIELD_LENGTH_IS_LOWER_THAN_MIN:
      'O comprimento do campo é menor do que o mínimo',
    FIELD_MUST_BE_DATE: 'O campo deve ser uma data',
    FIELD_MUST_BE_URL: 'O campo deve ser uma URL',
    FIELD_MUST_BE_LATITUDE: 'O campo deve ser uma latitude',
    FIELD_MUST_BE_LONGITUDE: 'O campo deve ser uma longitude',
    FIELD_MUST_BE_NUMBER: 'O campo deve ser um número',
    FIELD_MUST_BE_MOBILE_PHONE: 'O campo deve ser um número de telefone móvel',
    FIELD_MUST_BE_ARRAY: 'O campo deve ser um array',
    FIELD_ARRAY_SIZE_IS_LOWER_THAN_MIN:
      'O tamanho do array do campo é menor do que o mínimo',
    FIELD_ARRAY_SIZE_IS_GREATER_THAN_MAX:
      'O tamanho do array do campo é maior do que o máximo',
    FIELD_INVALID_VALUE: 'O campo possui um valor inválido',
    INVALID_NESTED_ATTRIBUTES:
      'O campo possui atributos aninhados com valor inválido',
  };
};
