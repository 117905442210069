import { WebResources } from '@/services/webResources';

type GetIncomeEstablishmentGroup = {
  id: string;
  month: string;
  year: string;
};

const apiEndpoints = {
  auth: '/api/auth',
  getCheckin: (id: string) => `/api/checkins/get/${id}`,
  getEstablishments: (id: string) => `/api/auth/getEstablishments/${id}`,
  getPaginatedEstablishments: (id: string, page: number) =>
    `/api/auth/getPaginatedEstablishments/${id}?page=${page}`,
  getAllCheckins: (id: string) => `/api/checkins/all/${id}`,
  cancelCheckin: (id: string) => `/api/checkins/cancel/${id}`,
  doneCheckin: (id: string) => `/api/checkins/closes/${id}`,
  confirmCheckin: (id: string) => `/api/checkins/confirm/${id}`,
  getGym: (id: string) => `/api/gyms/get/${id}`,
  getFranchiseUnit: (id: string) => `/api/gyms/franchise/${id}`,
  getNeighborhoods: (id: string) => `/api/cities/${id}/neighborhoods`,
  updateGym: (id: string) => `/api/gyms/update/${id}`,

  getIncome: (id: string) => `/api/gyms/income/${id}`,
  getRelation: () => `/api/gyms/relation`,
  getDebitNotes: (id: string) => `/api/debit-notes/all/${id}`,
  getDebitNotePdf: (id: string) => `/api/debit-notes/pdf/${id}`,
  signDebitNote: (id: string) => `/api/debit-notes/sign/${id}`,
  confirmAwaitingCheckin: (id: string) =>
    `/api/checkins/confirm-awaiting/${id}`,
  manualRefundCheckin: (id: string) => `/api/checkins/manual-refund/${id}`,
  getProducts: (id: string) => `/api/products/all/${id}`,
  updateEstablishmentProducts: (id: string) =>
    `api/products/admin/update/${id}`,
  getEstablishmentProducts: () => `/api/products/admin`,
  getPromotions: (establishmentId: string) =>
    `/api/promotions/all/${establishmentId}`,
  addPromotion: () => '/api/promotions/add',
  deactivePromotion: (id: string) => `/api/promotions/${id}/deactive`,
  activePromotion: (id: string) => `/api/promotions/${id}/active`,
  notifications: (id: string) => `/api/notifications/${id}/all`,
  getIndicationCode: (id: string) => `/api/indication-code/${id}`,
  getEstablishmentIndications: (id: string) =>
    `/api/establishment-indication/${id}/indications`,
  getEstablishmentIndicationMoney: (id: string) =>
    `/api/establishment-indication/${id}/money`,

  getEstablishmentGroupMe: (id: string) =>
    `/api/auth/getEstablishmentGroup/${id}`,
  getEstablishmentGroup: (page: number) =>
    `/api/gyms/get/establishment-group?page=${page}`,
  signDebitNoteEstablishmentGroup: (id: string) =>
    `/api/debit-notes/franchise/sign/${id}`,
  getDebitNotesEstablishmentGroup: (id: string) =>
    `/api/debit-notes/franchise/all/${id}`,
  getDebitNoteEstablishmentGroupUnique: (id: string) =>
    `/api/debit-notes/franchise/unique/${id}`,

  getEstablishmentTokens: (establishmentId: string) =>
    `/api/tokens/${establishmentId}`,
  createEstablishmentTokens: (establishmentId: string) =>
    `/api/tokens/${establishmentId}/create`,

  getIncomeEstablishmentGroup: ({
    id,
    month,
    year,
  }: GetIncomeEstablishmentGroup) =>
    `/api/gyms/franchise/income/${id}?month=${month}&year=${year}`,

  getRelationEstablishmentGroup: () => `/api/gyms/franchise/relation`,
  getWebResource: (type: WebResources) => `/api/web-resource/${type}`,
  setAutoAcceptDebitNote: (id: string) =>
    `/api/establishments/${id}/set-auto-accept-debit-note`,

  reports: (id: string) =>
    `/api/establishment-worker/establishments/${id}/report`,
};

const serverEndpoints = {
  authPartner: '/establishment-workers/login/',
  firstAccess: '/establishment-workers/first-login/',
  getPaginatedEstablishments: () => `/establishment-workers/establishments`,
  getEstablishments: (id: string) =>
    `/establishment-workers/establishments/${id}`,
  getEstablishmentProducts: () =>
    `/establishment-worker/products/filter?select=all&limit=300`,
  updateEstablishmentProducts: (id: string) =>
    `/establishment-worker/products/change-request/${id}`,
  getCheckin: (id: string) => `/establishmentworker/checkins/${id}`,
  getAllCheckins: (id: string) =>
    `/establishment-worker/establishments/${id}/checkins`,
  cancelCheckin: (id: string) => `/establishmentworker/checkins/cancel/${id}`,
  doneCheckin: (id: string) => `/establishmentworker/checkins/done/${id}`,
  confirmCheckin: (id: string) => `/establishmentworker/checkins/confirm/${id}`,
  getGym: (id: string) => `/establishment-worker/establishments/${id}`,
  getIndicationCode: (id: string) =>
    `/establishment-worker/indication-code/establishment/${id}`,
  getNeighborhoods: (id: string) =>
    `/establishment-worker/cities/${id}/neighborhoods`,
  updateGym: (id: string) => `/establishment-worker/establishments/${id}`,
  getIncome: (id: string) =>
    `/establishment-worker/establishments/${id}/income`,
  getRelation: (id: string) =>
    `/establishment-worker/establishments/${id}/checkin/relation`,
  getDebitNotes: (id: string) => `/establishment-worker/debit-note/${id}`,
  getDebitNotePdf: (id: string) => `/establishment-worker/debit-note/${id}/pdf`,
  signDebitNote: (id: string) => `/establishment-worker/debit-note/${id}/sign`,
  confirmAwaitingCheckin: (id: string) =>
    `/establishmentworker/checkins/confirm-awaiting/${id}`,
  manualRefundCheckin: (id: string) =>
    `/establishmentworker/checkins/cancel/${id}`,
  getEstablishmentIndications: (id: string) =>
    `/establishment-worker/establishment-indication/${id}`,
  getEstablishmentIndicationMoney: (id: string) =>
    `/establishment-worker/establishment-indication/${id}/get-indications-value`,
  getProducts: (id: string) =>
    `/establishment-worker/products/filter?select=all&filter[0][path]=establishmentId&filter[0][value]=${id}`,
  getPromotions: (id: string) =>
    `/establishment-worker/establishments/getAllPromotions/${id}`,
  addPromotion: () => '/establishment-worker/promotion-products',
  deactivePromotion: (id: string) =>
    `/establishment-worker/promotion-products/deactive/${id}`,
  activePromotion: (id: string) =>
    `/establishment-worker/promotion-products/active/${id}`,
  notifications: (id: string) =>
    `/establishment-worker/establishments/${id}/notifications/filter?select=all`,

  authFranchisee: '/establishment-group/login/',
  getEstablishmentGroupMe: () => `/establishment-group/me`,

  getEstablishmentGroup: (page: string) =>
    `establishment-group/establishments/with-income-report?limit=30&page=${page}`,
  getFranchiseUnit: (id: string) => `/establishment-group/establishments/${id}`,

  signDebitNoteEstablishmentGroup: (id: string) =>
    `/establishment-group-debit-note/${id}/sign`,
  getDebitNotePdfEstablishmentGroup: (id: string) =>
    `/establishment-group-debit-note/${id}/pdf`,

  getDebitNoteEstablishmentGroupAll: () => `/establishment-group-debit-note/`,
  getDebitNoteEstablishmentGroupUnique: (establishmentId: string) =>
    `/establishment-group/establishment-debit-note/${establishmentId}`,

  getIncomeEstablishmentGroup: ({ month, year }: GetIncomeEstablishmentGroup) =>
    `/establishment-group/income-report/${month}/${year}`,

  getAllIncomeEstablishmentGroup: ({
    month,
    year,
  }: GetIncomeEstablishmentGroup) =>
    `/establishment-group/income-report/${month}/${year}`,

  resetPassword: '/establishment-workers/request_reset_password',
  changePassword: '/establishment-workers/reset_password',

  getRelationEstablishmentGroup: () => `/establishment-group/income-report/csv`,
  getWebResource: (type: WebResources) => `/user/web-resource/${type}`,

  setAutoAcceptDebitNote: (id: string) =>
    `/establishment-worker/establishments/${id}/set-auto-accept-debit-note`,

  reports: (id: string) => `/establishment-worker/establishments/${id}/report`,

  getEstablishmentTokens: (establishmentId: string) =>
    `/v2/establishment-worker/establishment-token?establishmentId=${establishmentId}`,

  createEstablishmentToken: `/v2/establishment-worker/establishment-token/`,
};

export { apiEndpoints, serverEndpoints };
