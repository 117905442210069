import React, { FC } from 'react';
import Head from 'next/head';

interface TitleProps {
  title: string;
}

export const Title: FC<TitleProps> = ({ title }) => (
  <Head>
    <title>{title} - Gurupass</title>
    <link rel="icon" type="image/png" sizes="64x64" href="/favicon.png" />
  </Head>
);

export default Title;
