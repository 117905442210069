import * as Sentry from '@sentry/nextjs';
import { apiEndpoints } from '@/config/endpoints';

import { clientPatch } from '@/services/client';
import { Establishment } from '@/types/establishment';

export const setAutoAcceptDebitNote = async (
  id: string,
  autoAcceptDebitNote: boolean
): Promise<Establishment> => {
  try {
    const response = await clientPatch(
      apiEndpoints.setAutoAcceptDebitNote(id),
      {
        autoAcceptDebitNote,
      }
    );
    return response;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
