import styled from 'styled-components';

interface ContainerProps {
  width?: number;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: 70px;
  border-radius: 165px;
  cursor: pointer;
  gap: 10px;
  padding-left: 10px;
  border: 6.58px solid rgba(255, 255, 255, 0.3);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Text = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 15.4px;
  color: #333333;
`;

export const Subtitle = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 11px;
  color: #888888;
`;
