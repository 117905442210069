import React, { useState, useEffect, useCallback } from 'react';

import { toast } from 'react-toastify';
import { clientGet, clientPost } from '@/services/client';

import useStore from '@/store';

import { apiEndpoints } from '@/config/endpoints';

import DateHelper from '@/helpers/dateHelper';

import { ModalConfirmControllerProps, ModalConfirmProps } from './types';

export const useModalConfirmController = ({
  userData,
  open,
  onClose,
  startReservationHour,
  getIncome,
  invalidateCheckinQuery,
}: ModalConfirmProps): ModalConfirmControllerProps => {
  const { updateGetList } = useStore(state => state);
  const [isFirst, setIsFirst] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState<string>('');

  const dateHelper = new DateHelper(startReservationHour);
  const reservationDate = dateHelper.formatDate(true);

  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = () => onClose?.(false);

  const onGetCheckin = useCallback(async () => {
    const response = await clientGet(apiEndpoints.getCheckin(userData.id));
    setIsFirst(response?.firstTime);
  }, [userData.id]);

  useEffect(() => {
    open && onGetCheckin();
  }, [onGetCheckin, open]);

  const onApplyCode = async () => {
    try {
      if (!confirmationCode?.trim()) {
        toast.error('Por favor, informe o código do check-in.', {
          position: 'top-right',
          autoClose: 1000,
        });
        return;
      }

      setLoading(true);

      const res = await clientPost(apiEndpoints.confirmCheckin(userData?.id), {
        confirmationCode,
      });

      if (res) {
        getIncome?.();
        toast.success('Código aplicado', {
          position: 'top-right',
          autoClose: 1000,
        });
        setConfirmationCode('');
        handleClose();
        updateGetList(true);
      }

      invalidateCheckinQuery();
    } catch (err) {
      toast.error('Ocorreu um erro ao aplicar o código.', {
        position: 'top-right',
        autoClose: 1000,
      });
    } finally {
      setLoading(false);
      setConfirmationCode('');
    }
  };

  return {
    handleClose,
    reservationDate,
    isFirst,
    confirmationCode,
    setConfirmationCode,
    loading,
    onApplyCode,
    open,
  };
};

export default useModalConfirmController;
