import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import { isYesterday, isThisWeek, isToday, isFuture } from 'date-fns';

import { useConfirmationModalStore } from '@/store/modal/ConfirmationModal/ConfirmationModal';

import {
  CancelationModalProps,
  OnOpenModalTypeProps,
} from '@/components/common/Card/types';

import useStore from '@/store';

import { clientPost } from '@/services/client';
import DateHelper from '@/helpers/dateHelper';

import { statusOrder } from '@/config/status';
import { apiEndpoints } from '@/config/endpoints';

import { Product } from '@/types/product';
import { Status } from '@/types/status';

import {
  CardsReservationControllerProps,
  CardsReservationControllerReturn,
  CardsReservationsData,
  ReservationData,
} from './types';

import 'swiper/css';

export const useCardReservationsController = ({
  data,
  time,
}: CardsReservationControllerProps): CardsReservationControllerReturn => {
  const { updateGetList } = useStore(state => state);
  const { handleConfirmationModalOpen, isOpenConfirmationModal } =
    useConfirmationModalStore();

  const [dataReservations, setDataReservations] = useState<
    CardsReservationsData[]
  >([]);

  const [openModalView, setOpenModalView] = useState<boolean>(false);
  const [openModalCancellation, setOpenModalCancellation] =
    useState<boolean>(false);
  const [openModalClosure, setOpenModalClosure] = useState<boolean>(false);
  const [openModalConfirmReservation, setOpenModalConfirmReservation] =
    useState<boolean>(false);

  const [openAwaitingConfirmation, setOpenAwaitingConfirmation] =
    useState<boolean>(false);

  const [openModalCancellationReason, setOpenModalCancellationReason] =
    useState<boolean>(false);

  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
  const [dataModal, setDataModal] = useState<ReservationData>({
    id: '',
    name: '',
    startReservationHour: '',
    endReservationHour: '',
    document: '',
    product: {} as Product,
    createdAt: '',
  });

  const openModalForType = (type: string) => {
    switch (type) {
      case 'awaiting_confirmation':
        setOpenAwaitingConfirmation(true);
        break;

      case 'view':
        setOpenModalView(true);
        break;
    }
  };

  const openModalForStatus = (status: Status, type: string) => {
    if (status === 'PENDING') {
      if (type === 'close') {
        setOpenModalCancellation(true);
        setIsConfirmModal(false);
      } else {
        setOpenModalConfirmReservation(true);
      }
    }

    if (status === 'CONFIRMED') {
      setOpenModalClosure(true);
      setIsConfirmModal(true);
    }
  };

  const handleModalCard = (
    {
      id,
      name,
      status,
      document,
      startReservationHour,
      endReservationHour,
      product,
      createdAt,
    }: OnOpenModalTypeProps,
    type: string
  ) => {
    const defaultDataModal = {
      id,
      name,
      startReservationHour,
      endReservationHour: endReservationHour || '',
      document: document ?? '',
      product: product ?? { name: '' },
      createdAt: createdAt ?? '',
    };

    openModalForType(type);
    openModalForStatus(status, type);

    setDataModal(defaultDataModal);
  };

  const timeFilters: Record<string, (date: Date) => boolean> = {
    today: isToday,
    future: isFuture,
    yesterday: isYesterday,
    default: isThisWeek,
  };

  const filterByTime = useCallback(
    (startReservationHour: Date): boolean => {
      const filterFunction = timeFilters[time] || timeFilters.default;
      return filterFunction(startReservationHour);
    },
    [time]
  );

  const filterAndSortReservations = useCallback(() => {
    if (data?.length <= 0) return;

    const filteredReservations =
      data?.filter(({ startReservationHour }) =>
        filterByTime(new Date(startReservationHour))
      ) ?? [];

    const sortedReservations = filteredReservations.sort((a, b) => {
      return (
        statusOrder.indexOf(a.status.toLowerCase()) -
        statusOrder.indexOf(b.status.toLowerCase())
      );
    });

    setDataReservations(sortedReservations);
  }, [data, filterByTime]);

  useEffect(() => {
    filterAndSortReservations();
  }, [filterAndSortReservations, time]);

  const handleApiCall = async (endpoint: string, body?: any) => {
    toast.warning('Aguarde...', {
      position: 'top-right',
      autoClose: 1000,
    });

    try {
      const response = await clientPost(endpoint, body);
      if (response) {
        updateGetList(true);
      }
      return response;
    } catch (error) {
      toast.error('Tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 1000,
      });
    }
  };

  const handleCancelCheckin = async (cancellationReason: string) => {
    const response = await handleApiCall(
      apiEndpoints.cancelCheckin(dataModal?.id),
      cancellationReason ? { cancellationReason } : null
    );

    if (response?.status === 'REFUNDED') {
      toast.success('Checkin cancelado!', {
        position: 'top-right',
        autoClose: 1000,
      });
    }
  };

  const handleDoneCheckin = async () => {
    await handleApiCall(apiEndpoints.doneCheckin(dataModal?.id));
  };

  const formatReservationDate = (): string => {
    const dateHelper = new DateHelper(dataModal?.startReservationHour);
    const formattedDate = dateHelper.formatDate(true);
    return formattedDate;
  };

  const generateCancelationMessage = (
    dataModal: CancelationModalProps
  ): string | null => {
    if (!dataModal?.name) return null;

    const { name, product } = dataModal;
    const reservationDate = formatReservationDate();
    return `${name} fez a reserva de ${product.name} para o dia ${reservationDate}`;
  };

  const modalCancelationMessage = generateCancelationMessage(dataModal);

  const reservationsCount = dataReservations?.length;
  const haveReserveCard = reservationsCount > 0;

  const reservationsFiltered = dataReservations?.filter(
    ({ status }) => !['REFUNDED'].includes(status)
  );
  const totalCheckins = reservationsFiltered?.length ?? 0;

  return {
    openModalCancellation,
    dataModal,
    setOpenModalCancellation,
    openModalCancellationReason,
    setOpenModalCancellationReason,
    handleCancelCheckin,
    isConfirmModal,
    openModalClosure,
    setOpenModalClosure,
    handleDoneCheckin,
    openModalView,
    setOpenModalView,
    openModalConfirmReservation,
    setOpenModalConfirmReservation,
    openAwaitingConfirmation,
    setOpenAwaitingConfirmation,
    isOpenConfirmationModal,
    handleConfirmationModalOpen,
    dataReservations,
    handleModalCard,
    modalCancelationMessage,
    haveReserveCard,
    reservationsCount,
    totalCheckins,
  };
};

export default useCardReservationsController;
