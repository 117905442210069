import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

export const Content = styled.div`
  padding: 15px;
  background-color: #fff;
  border-radius: 15px;
  padding: 40px;
  width: 646px;
  border: 6.58px solid rgba(255, 255, 255, 0.3);
  box-shadow: 4px 4px 44px rgba(0, 0, 0, 0.06);

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 20px;
    height: 300px;
    overflow: scroll;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const Title = styled.p`
  font-family: 'Graphik', sans-serif;
  font-weight: 500;
  line-height: 39.76px;
  color: #333333;

  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
    font-weight: bold;
  }
`;

export const Text = styled.p`
  font-family: 'Graphik', sans-serif;
  font-weight: 400;
  font-size: 1.188rem;
  line-height: 26px;
  color: #888888;
  text-align: justify;

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const ContainterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 40px;
`;

export const TextAlert = styled.div`
  font-family: 'Graphik', sans-serif;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 12.1px;
  color: #000;

  @media screen and (max-width: 768px) {
    font-size: 0.6rem;
    line-height: 12.1px;
  }
`;

export const Button = styled.div`
  cursor: pointer;
`;
