import styled from 'styled-components';

interface ContainerProps {
  active: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100px;
  height: 34px;
  background: ${({ active }) =>
    active ? '#D8F32E' : 'rgba(255, 255, 255, 0.6)'};
  border: 1px solid #ffffff;
  backdrop-filter: blur(2px);
  border-radius: 20px;
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: ${({ active }) => (active ? '#000' : '#737373')};
  padding: 11px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
