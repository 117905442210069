import { toast } from 'react-toastify';
import { getErrorMessage } from './translate';

export const toastError = (message: string | string[]) => {
  if (!message) return;

  const isArray = Array.isArray(message);
  return toast.error(
    isArray ? getErrorMessage(message[0]) : getErrorMessage(message),
    {
      position: 'top-right',
      autoClose: 2000,
    }
  );
};

export default toastError;
