import styled from 'styled-components';

export const DigitalClockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Graphik', sans-serif;
  color: #434343;
  font-size: 2.5rem;
`;
