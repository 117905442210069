import axios from 'axios';
import { getItemStorage } from '@/services/storage';

const serverAPI =
  process.env.NEXT_PUBLIC_SERVER_API || 'https://api-staging.gurupass.com.br';
const clientAPI = process.env.NEXT_PUBLIC_CLIENT_API;

export const apiServer = (token?: string) => {
  const instance = axios.create({ baseURL: serverAPI });

  instance.interceptors.request.use(async request => {
    if (token) {
      request.headers.Authorization = token;
    }

    return request;
  });

  return instance;
};

export const apiAuth = () => axios.create({ baseURL: clientAPI });

export const apiClient = () => {
  const bearerToken =
    getItemStorage('daypass_access_token') ||
    getItemStorage('daypass_access_token_group');

  const instance = axios.create({ baseURL: clientAPI });

  instance.interceptors.request.use(async request => {
    if (!bearerToken && global?.window) {
      window.location.replace('/login');
      return request;
    }

    request.headers.Authorization = `Bearer ${bearerToken}`;

    return request;
  });

  instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error?.response?.status === 400) {
        throw error;
      }
    }
  );

  return instance;
};
