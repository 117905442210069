import React, { type FC, useState } from 'react';
import Image from 'next/image';

import useStore from '@/store';
import useUserType from '@/hooks/useUserType';

import Items from '../Items';

import { Container, Logo, HamburgerButton, MobileMenu } from './styles';

export const Mobile: FC = () => {
  const { loginAs, firstAccess, updateGetList } = useStore(state => state);
  const { isFranchise, isOwner } = useUserType(loginAs);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <Container>
        <Logo>
          <Image
            src="/Gurupass-Sidebar.svg"
            alt="Gurupass"
            title="Gurupass"
            width={26}
            height={49}
          />
        </Logo>
        <HamburgerButton onClick={toggleMobileMenu}>
          <span />
          <span />
          <span />
        </HamburgerButton>
      </Container>
      {isMobileMenuOpen && (
        <MobileMenu>
          <Items
            firstAccess={firstAccess}
            isFranchise={isFranchise}
            isOwner={isOwner}
            updateGetList={updateGetList}
            toogle={toggleMobileMenu}
          />
        </MobileMenu>
      )}
    </>
  );
};
