import useStore from '@/store';
import { useRouter } from 'next/router';

import { getItemStorage } from '@/services/storage';
import { useUserType } from '@/hooks/useUserType';

import { TemplateControllerResult } from './types';

const useTemplateController = (): TemplateControllerResult => {
  const { userUuid, firstAccess, establishment, loginAs, establishmentGroup } =
    useStore(state => state);
  const accessToken = getItemStorage('daypass_access_token');
  const router = useRouter();
  const pathName = router.pathname;
  const isNotLoginOrRecover =
    pathName !== '/login' && pathName !== '/resetar-senha';
  const { isFranchise } = useUserType(loginAs);

  const isNotAuthenticated = !userUuid || !accessToken;

  if (isNotAuthenticated && isNotLoginOrRecover && !isFranchise) {
    router.push('/login');
  }

  if (!firstAccess && router.pathname === '/firstAccess') {
    router.push('/');
  }

  return {
    establishment,
    isFranchise,
    establishmentGroup,
  };
};

export default useTemplateController;
