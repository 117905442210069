import { useCallback, useEffect, useState } from 'react';
import useStore from '@/store';

import { clientGet } from '@/services/client';

import { apiEndpoints } from '@/config/endpoints';

export const useShowUnitsController = () => {
  const { userUuid } = useStore(state => state);

  const [loading, setLoading] = useState(false);
  const [numberOfEstablishments, setNumberOfEstablishments] = useState(0);

  const fetchEstablishments = useCallback(async () => {
    try {
      setLoading(true);

      const establishments = await clientGet(
        apiEndpoints.getEstablishmentGroup(1)
      );

      setNumberOfEstablishments(establishments?.totalResults ?? 0);
    } finally {
      setLoading(false);
    }
  }, [userUuid]);

  useEffect(() => {
    fetchEstablishments();
  }, [fetchEstablishments]);

  return {
    loading,
    numberOfEstablishments,
  };
};

export default useShowUnitsController;
