import { useEffect } from 'react';
import useStore from '@/store';

export const useRedirectIfFranchisee = () => {
  const { loginAs } = useStore(state => state);

  useEffect(() => {
    if (loginAs === 'Franchisee') {
      window.location.href = '/financeiro';
    }
  }, [loginAs]);
};

export default useRedirectIfFranchisee;
