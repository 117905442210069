import { create } from 'zustand';

import { type ConfirmationModalProps } from './ConfirmationModal.props';

const initialState = {
  isOpenConfirmationModal: false,
  clientName: '',
  date: new Date(),
  gymClassName: '',
  userId: '',
  isConfirm: false,
};

const useConfirmationModalStore = create<ConfirmationModalProps>(set => ({
  ...initialState,
  handleSetIsConfirm: (isConfirm: boolean) => set({ isConfirm }),
  handleSetUserId: (userId: string) => set({ userId }),
  handleSetGymClassName: (gymClassName: string) => set({ gymClassName }),
  handleSetDate: (date: Date) => set({ date }),
  handleSetClientName: (clientName: string) => set({ clientName }),
  handleConfirmationModalOpen: () =>
    set(state => ({ isOpenConfirmationModal: !state.isOpenConfirmationModal })),
}));

export { useConfirmationModalStore };
