import React, { type FC } from 'react';

import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';

import { useFirstAccessModalController } from './useFirstAcessModal';

import {
  Container,
  Header,
  Title,
  Text,
  Content,
  ContainterButton,
  TextAlert,
  Button,
} from './styles';

export const FirstAccessModal: FC = () => {
  const { handleAcceptTerms, termsAccepted, alertModal, handleAlertModal } =
    useFirstAccessModalController();

  if (!alertModal && termsAccepted) return <></>;

  return (
    <Container>
      <Content>
        <Header>
          <Title>Alerta!</Title>
          {alertModal && (
            <Button>
              <AiFillCloseCircle
                size={41}
                color="#D8F32E"
                onClick={() => handleAlertModal()}
              />
            </Button>
          )}
        </Header>
        <Text>
          Como política interna, a Gurupass não permite checkins de contas
          criadas por, ou associadas (dados cadastrais, método de pagamentos,
          etc.) a donos dos estabelecimentos, funcionários ou seus familiares
          nos próprios locais. Caso queira testar o funcionamento da plataforma,
          entre em contato com o suporte para a disponibilização de uma conta
          teste. O check-in deve ser confirmado quando o usuário chegar ao
          estabelecimento para o treino. Check-ins confirmados remotamente
          também não são permitidos.
          <br />
          <br />
          Caso check-ins realizados no estabelecimentos se enquadrem em algum
          dos critérios acima, a não ser que tenham sido previamente avisados ao
          atendimento, o repasse do mês será zerado e a continuação da parceria
          reavaliada.
        </Text>
        {!alertModal && (
          <ContainterButton>
            <TextAlert>
              Ao clicar no botão ao lado, você afirma estar ciente do comunicado
              acima.
            </TextAlert>
            <Button>
              <AiFillCheckCircle
                size={41}
                color="#D8F32E"
                onClick={() => handleAcceptTerms()}
              />
            </Button>
          </ContainterButton>
        )}
      </Content>
    </Container>
  );
};

export default FirstAccessModal;
