import React, { type FC } from 'react';

import useShowUnitsController from './useShowUnitsController';

import {
  Container,
  Content,
  Title,
  Border,
  NumberUnities,
  Text,
  Unitis,
} from './styles';

export const ShowUnits: FC = () => {
  const { numberOfEstablishments } = useShowUnitsController();

  return (
    <Container>
      <Title>Unidades</Title>

      <Content>
        <Border>
          <NumberUnities>{numberOfEstablishments ?? 0}</NumberUnities>
          <Text>unidades cadastradas</Text>
        </Border>
        <Unitis href="/escolha">visualizar unidades</Unitis>
      </Content>
    </Container>
  );
};

export default ShowUnits;
