import React from 'react';

import CloseIcon from '../components/Icons/Close';

import { CardsProps } from '../types';

import {
  CardContainer,
  Header,
  Activity,
  TimeInfo,
  ButtonContainer,
  Button,
  Icon,
  Infos,
} from './styles';

export const Canceled: React.FC<CardsProps> = ({
  name,
  activity,
  date,
  time,
  ref,
}) => {
  return (
    <CardContainer ref={ref}>
      <Header>{name}</Header>
      <Infos>
        <Activity>{activity}</Activity>
        <TimeInfo>{`${date} • ${time}`}</TimeInfo>
      </Infos>
      <ButtonContainer>
        <Button>
          Não compareceu
          <Icon>
            <CloseIcon />
          </Icon>
        </Button>
      </ButtonContainer>
    </CardContainer>
  );
};

export default Canceled;
