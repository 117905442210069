import React, { useCallback, useEffect, useState } from 'react';

import useStore from '@/store';
import { setItemStorage, getItemStorage } from '@/services/storage';

import { UseFirstAccessModalController } from './types';

export const useFirstAccessModalController =
  (): UseFirstAccessModalController => {
    const { establishment, alertModal, updateAlertModal } = useStore(
      state => state
    );
    const [termsAccepted, setTermsAccepted] = useState<boolean>(true);

    const handleAlertModal = () => {
      updateAlertModal(false);
    };

    const handleAcceptTerms = (): void => {
      const keyTerms = `gurupass:terms:${establishment?.slug}`;
      setItemStorage(
        keyTerms,
        JSON.stringify({
          accepted: true,
        })
      );

      setTermsAccepted(true);
      handleAlertModal();
    };

    const getTermsAccepted = useCallback((slug: string): boolean => {
      const keyTerms = `gurupass:terms:${slug}`;
      const termsStorage = getItemStorage(keyTerms);

      if (termsStorage !== null) {
        const data = JSON.parse(termsStorage as any);
        return data.accepted === true;
      }

      return false;
    }, []);

    const setStateTerms = useCallback(() => {
      setTermsAccepted(
        establishment?.slug ? getTermsAccepted(establishment.slug) : false
      );
    }, [establishment?.slug, getTermsAccepted]);

    useEffect(() => {
      if (establishment?.slug) {
        setTermsAccepted(getTermsAccepted(establishment.slug));
      }
    }, [establishment?.slug, getTermsAccepted]);

    useEffect(() => {
      setStateTerms();
    }, [establishment?.slug, getTermsAccepted, setStateTerms]);

    return {
      handleAcceptTerms,
      termsAccepted,
      alertModal,
      handleAlertModal,
    };
  };

export default useFirstAccessModalController;
