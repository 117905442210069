import React, { FC } from 'react';

import { Container } from './styles';

interface LoadingProps {
  width?: number;
  height?: number;
  border?: number;
  borderColor?: string;
}

export const Loading: FC<LoadingProps> = ({
  width,
  height,
  border,
  borderColor,
}) => (
  <Container
    width={width}
    height={height}
    border={border}
    borderColor={borderColor}
    className="loading"
  />
);
