/**
 * Formata o cpf para o formato xxx.xxx.xxx-xx
 *
 * @param cpfNumber - cpf a ser formatado
 * @returns cpf formatado no formato xxx.xxx.xxx-xx
 */

export const formatCpf = (cpfNumber: string) => {
  if (cpfNumber)
    return cpfNumber
      .replace(/\D/g, '')
      .slice(0, 14)
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};
