import React, { type FC } from 'react';
import Image from 'next/image';

import { NotificationBoxProps } from './type';

import {
  CloseContainer,
  LinkText,
  MainText,
  NotificationContainer,
  CenteredContent,
} from './NotificationBox.styles';

const NotificationBox: FC<NotificationBoxProps> = ({
  title,
  linkText,
  showButtonClose = true,
  handleClose,
  onClick,
  content,
}) => {
  return (
    <NotificationContainer
      data-testid="notification-container"
      onClick={() => onClick({ title, content })}>
      <Image
        src="/img/bell.svg"
        alt="Alerta ícone de notificação"
        width={39}
        height={39}
      />
      {showButtonClose ? (
        <div>
          <MainText>{title}</MainText>
          <LinkText href="/notificacoes">{linkText}</LinkText>
        </div>
      ) : (
        <CenteredContent>
          <div>
            <MainText>{title}</MainText>
            <LinkText href="/notificacoes">{linkText}</LinkText>
          </div>
        </CenteredContent>
      )}
      {showButtonClose && handleClose && (
        <CloseContainer onClick={() => handleClose({ title: '', message: '' })}>
          <Image
            src="/img/closeIcon.svg"
            alt="Botão de fechar notificação"
            width={24}
            height={24}
          />
        </CloseContainer>
      )}
    </NotificationContainer>
  );
};

export default NotificationBox;
