import { useCallback, useEffect, useState } from 'react';
import useStore from '@/store';
import { clientGet } from '@/services/client';
import { apiEndpoints } from '@/config/endpoints';
import { IEstablishmentIndicationCode } from '@/types/establishment-indication-code';
import { toast } from 'react-toastify';

const useIndicationCode = () => {
  const { establishment } = useStore(state => state);
  const [indicationCode, setIndicationCode] = useState<string>('');

  const getIndicationCode = useCallback(async () => {
    if (!establishment?.id) return;

    try {
      const response = (await clientGet(
        apiEndpoints.getIndicationCode(establishment?.id as string)
      )) as IEstablishmentIndicationCode;

      setIndicationCode(response.code);
    } catch (error) {
      toast.error('Não foi possível carregar o código de indicação');
    }
  }, [establishment?.id]);

  useEffect(() => {
    void getIndicationCode();
  }, [getIndicationCode]);

  return { indicationCode };
};

export { useIndicationCode };
