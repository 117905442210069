import React, { FC } from 'react';

interface TagSelectProps {
  value: number;
  setValue: (value: number) => void;
  options?: {
    value: number;
    label: string;
  }[];
  id: string;
}

export const TagSelect: FC<TagSelectProps> = ({
  value,
  setValue,
  options,
  id,
}) => (
  <select
    data-testid={`tag-select-${id}`}
    value={value}
    onChange={e => setValue(Number(e.target.value) || 0)}
    className="bg-[#f8f8f8] border backdrop-blur-[2px] w-[120px] h-[40px] not-italic font-medium text-[13px] text-[#333333] pl-2.5 rounded-[20px] border-solid border-[#DDDDDD] focus:outline-none focus:ring-0 focus:border-[#DDDDDD]">
    {options &&
      options.map(
        option =>
          option && (
            <option
              data-testid={option.label}
              value={option.value}
              key={option.value}
              style={{ color: '#000000' }}
              className="not-italic font-medium text-[13px] text-black !important">
              {option.label}
            </option>
          )
      )}
  </select>
);

export default TagSelect;
