import Link from 'next/link';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

export const Title = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 19.8px;
  color: #333333;
`;

export const Border = styled.div`
  width: 180px;
  height: 70px;
  border-radius: 10px;
  border: 1px solid #dddddd;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  gap: 5px;
`;

export const NumberUnities = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 22px;
  color: #313a40;
`;

export const Text = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 13px;
  color: #888888;
`;

export const Unitis = styled(Link)`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.851rem;
  line-height: 20.28px;
  color: #313a40;
  border-bottom: 1px solid #313a40;
  display: inline-block;
  margin-bottom: 5px;
`;
