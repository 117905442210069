import styled from 'styled-components';

interface IconProps {
  size?: number;
}

interface NotificationProps {
  show: boolean;
}

interface ToogleButtonProps {
  active: boolean;
}

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  height: 120px;
  margin-bottom: 20px;
  position: relative;

  .establishment-name {
    font-family: 'Graphik', sans-serif;
    font-size: 14px;
    color: #434343;
  }

  p {
    font-family: 'Graphik', sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #838383;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 40px;
    height: auto;
  }
`;

export const TitleAndTimerContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const LoginDetail = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 6px;
  justify-content: left;
  gap: 10px;
`;

export const TimerAndUserContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 390px;
  @media (max-width: 800px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
`;

export const Icon = styled.img<IconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size || 55}px;
  height: ${({ size }) => size || 55}px;
  border-radius: 50%;
`;

export const VerticalLine = styled.div`
  width: 2px;
  height: 60px;
  background: #e0e0e0;
`;

export const LoggedGymVerticalLine = styled.div`
  width: 2px;
  height: 60px;
  background: #e0e0e0;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const NotifContainer = styled.div`
  min-width: 350px;
  width: 350px;
  position: absolute;
  right: 0;

  @media (max-width: 800px) {
    position: relative;
  }

  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const VerticalLineContainer = styled.div`
  display: flex;
  gap: 1rem;
  @media (max-width: 480px) {
    gap: 0rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 350px;

  .title {
    font-family: 'Graphik', sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #838383;
  }

  .subtitle {
    font-family: 'Graphik', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #434343;
    margin-top: 5px;
  }

  @media (max-width: 800px) {
    width: 300px;
  }

  @media (max-width: 480px) {
    margin-left: 20px;
    width: 90%;
  }
`;

export const NotificationContainer = styled.div<NotificationProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  ${({ show }) =>
    !show &&
    `
    visibility: hidden;
  `}

  .notification {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 40px;
    background-color: #fff;
    padding: 0 1rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  }

  .notification-text-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-family: 'Graphik', sans-serif;
    padding: 0 1rem;
    cursor: pointer;
    margin-right: 36px;

    .notification-title {
      font-family: 'Graphik', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;
      color: #434343;
    }

    .notification-subtitle {
      font-family: 'Graphik', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 11px;
      color: #838383;
    }

    .icon-close {
      margin-right: 6px;
    }

    @media screen and (max-width: 998px) {
      display: none;
    }
  }

  @media screen and (max-width: 998px) {
    .notification-title,
    .notification-subtitle,
    .icon-close {
      display: none;
    }
    width: auto;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0 1rem;

  .btn-container {
    display: flex;
    background-color: transparent;
    border-radius: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
    padding: 5px;
    height: 60px;
  }
`;

export const ToggleButton = styled.button<ToogleButtonProps>`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 50px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Graphik', sans-serif;
  color: #434343;
  ${({ active }) =>
    active &&
    `
    background-color: #fff;
    border-radius: 20px;
  `}
  transition: all 0.2s ease-out;
`;
