import { isToday, isYesterday, format } from 'date-fns';

export const getFormattedDate = (date: Date): string => {
  const dateCompleted = format(date, 'dd/MM');

  if (isToday(date)) {
    return `Hoje, ${dateCompleted}`;
  } else if (isYesterday(date)) {
    return `Ontem, ${dateCompleted}`;
  } else {
    return dateCompleted;
  }
};
