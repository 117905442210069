import styled from 'styled-components';

export const PopupContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .close {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
  }
`;

export const ContentModal = styled.div`
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  padding: 16px;
  width: auto;
  border: 7px solid rgba(255, 255, 255, 0.3);
  box-shadow: 4px 4px 44px rgba(0, 0, 0, 0.06);
`;

export const PopupContent = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 499px;
  font-family: 'Graphik', sans-serif;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const PopupTitle = styled.h3`
  font-size: 20px;
  max-width: 499px;
  font-weight: 500;
  margin: 0;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const PopupDescription = styled.p`
  font-size: 14px;
  margin-top: 8px;
  color: #838383;
  padding: 0;
`;

export const PopupActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  svg {
    cursor: pointer;
  }
`;
