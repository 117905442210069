import React, { FC } from 'react';

import { Alert } from '@/components/common/Alert';
import ShowUnits from '@/components/common/ShowUnits';
import TagSelect from '@/components/common/TagSelect';
import IndicationCodeForUsers from '@/components/common/IndicationCodeForUsers';

import { monthFromNumber } from '@/utils/monthFromNumber';

import useStore from '@/store';
import { useUserType } from '@/hooks/useUserType';

import { Months, yearsOptions } from './contants';

import { IncomeSectionProps } from './types';
import AutoAccept from '../AutoAccept';

export const IncomeSection: FC<IncomeSectionProps> = ({
  income,
  month,
  year,
  setMonth,
  setYear,
  autoAcceptDebitNote,
}) => {
  const { loginAs } = useStore();
  const { isFranchise, isOwner } = useUserType(loginAs);

  return (
    <>
      {isOwner && (
        <>
          <div className="flex items-center justify-between">
            <h2 className="not-italic font-semibold text-lg leading-5 text-[#434343]">
              Seus rendimentos
            </h2>
            <TagSelect
              value={year}
              setValue={setYear}
              options={yearsOptions}
              id="year"
            />
            <TagSelect
              value={month}
              setValue={setMonth}
              options={Months}
              id="month"
            />
          </div>

          <div className="w-full min-h-[300px] bg-[rgba(255,255,255,0.6)] mt-[23px] rounded-[20px] flex flex-col p-12 relative">
            <h2 className="not-italic font-medium text-[20px]">Você tem</h2>
            <div className="not-italic font-medium text-[40px]">
              {((income?.monthIncomeInCents || 0) / 100)?.toLocaleString(
                'pt-BR',
                {
                  style: 'currency',
                  currency: 'BRL',
                }
              )}
            </div>

            <div className="not-italic font-normal text-sm text-[#888888]">
              acumulados no mês de {monthFromNumber(month)}.
            </div>
            <div className="flex flex-row justify-around mt-6">
              <div className="w-[40%] border rounded-[10px] border-solid border-[#DDDDDD] flex flex-col p-4">
                <h3 className="not-italic font-medium text-3xl">
                  {income?.todayCheckins}
                </h3>
                <p className="not-italic font-normal text-md text-[#888888]">
                  reservas hoje
                </p>
              </div>
              <div className="w-[40%] border rounded-[10px] border-solid border-[#DDDDDD] flex flex-col p-4">
                <h3 className="not-italic font-medium text-3xl">
                  {income?.monthCheckins}
                </h3>
                <p className="not-italic font-normal text-md text-[#888888]">
                  reservas esse mês
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {!isFranchise && (
        <div>
          <IndicationCodeForUsers />
        </div>
      )}
      <div className="flex items-center justify-center mb-5">
        {!isFranchise ? <Alert /> : <ShowUnits />}
      </div>

      {isOwner && !autoAcceptDebitNote && (
        <div className="flex items-center justify-end mb-5">
          <AutoAccept />
        </div>
      )}
    </>
  );
};
