import React, { type FC, useState } from 'react';
import { toast } from 'react-toastify';

import useStore from '@/store';

import { setAutoAcceptDebitNote } from '@/services/debitNotes/worker/setAutoAcceptDebitNote.service';

import { Loading } from '../Loading';

export const AutoAccept: FC = () => {
  const { establishment, updateEstablishment } = useStore(state => state);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleSetAutoAcceptDebitNote = async () => {
    if (!establishment) return;

    if (!checked) {
      toast.warning('Por favor, aceite os termos.');
      return;
    }

    setLoading(prev => !prev);
    try {
      const id = establishment.id;
      const autoAcceptDebitNote = true;
      const response = await setAutoAcceptDebitNote(id, autoAcceptDebitNote);

      if (response?.autoAcceptDebitNote) {
        toast.success('Aceito com sucesso!');
        updateEstablishment(response);
        return;
      }
      toast.warning('Ops, houve um problema.');
    } finally {
      setLoading(prev => !prev);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center w-[100%]">
        <Loading width={100} height={100} />
      </div>
    );
  }

  return (
    <div className="bg-main-green-500 p-6 rounded-lg shadow-md sm:w-[100%] md:w-[80%] lg:w-[80%]">
      <h2 className="text-xl font-semibold mb-4">Aceite automático</h2>
      <label
        htmlFor="acceptCheckbox"
        className="flex items-start cursor-pointer">
        <div className="mr-2 mt-1">
          <input
            type="checkbox"
            checked={checked}
            onChange={() => setChecked(!checked)}
            className="hidden"
            id="acceptCheckbox"
          />
          <div
            className={`w-5 h-5 rounded-full border-2 border-gray-400 flex items-center justify-center ${
              !checked ? 'bg-[#D8F42E]' : 'bg-black'
            }`}></div>
        </div>
        <p className="text-sm">
          Atesto que estou de acordo com todos os relatórios que serão gerados
          pela Gurupass, e gostaria de realizar o aceite automático das notas.
        </p>
      </label>
      <button
        onClick={() => handleSetAutoAcceptDebitNote()}
        className={`mt-4 w-full h-[50px] text-[#D8F42E] font-semibold rounded-md bg-black cursor-pointer'
        }`}>
        Aceitar
      </button>
    </div>
  );
};

export default AutoAccept;
