import { LoginAs } from '@/types/loginAs';
import { useStore } from '@/store';

export const useUserType = (loginAs: LoginAs) => {
  const { role } = useStore(state => state);

  const isFranchise = loginAs === 'Franchisee';
  const isOwner = role?.toLowerCase() === 'owner';

  return {
    isFranchise,
    isOwner,
  };
};

export default useUserType;
