import { translateResponse } from '@/config/translateResponse';
import { Errors } from '@/constant/errorMessage';
import { apiClient } from '@/services/api';

import toastError from '@/services/toastError';

const verifyIfIsLoggedIn = () => {
  const token = localStorage.getItem('daypass_access_token');
  if (!token && typeof window !== 'undefined') {
    window.location.replace('/login');
    return;
  }
};

const clientGet = async (path: string, body?: any) => {
  try {
    verifyIfIsLoggedIn();

    const res = await apiClient().get(path, body);
    return res.data;
  } catch (e: any) {
    const message = e?.response?.data.message;
    toastError(
      translateResponse[message] || 'Um erro estranho ocorreu, tente novamente.'
    );
  }
};

const clientPost = async (path: string, body?: any) => {
  try {
    verifyIfIsLoggedIn();

    const res = await apiClient().post(path, body);
    return res.data;
  } catch (e: any) {
    const message =
      (Errors as any)({})[e?.response?.data?.customCode] ||
      'Um erro estranho ocorreu, tente novamente em alguns instantes.';

    toastError(
      translateResponse[message] || 'Um erro estranho ocorreu, tente novamente.'
    );
  }
};

const clientPatch = async (path: string, body?: any) => {
  try {
    verifyIfIsLoggedIn();

    const res = await apiClient().patch(path, body);
    return res.data;
  } catch (e: any) {
    const message = e?.response?.data.message;
    toastError(
      translateResponse[message] || 'Um erro estranho ocorreu, tente novamente.'
    );
  }
};

export { clientGet, clientPost, clientPatch };
