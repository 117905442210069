import React from 'react';

import CheckIcon from '../components/Icons/Check';

import { CardsProps } from '../types';

import {
  CardContainer,
  Badge,
  Header,
  Activity,
  TimeInfo,
  ButtonContainer,
  Button,
  Icon,
  Infos,
} from './styles';

export const Confirmed: React.FC<CardsProps> = ({
  name,
  activity,
  date,
  time,
  onAccept,
  ref,
}) => {
  return (
    <CardContainer ref={ref}>
      <Badge>EM ANDAMENTO</Badge>
      <Header className="userName">{name}</Header>
      <Infos>
        <Activity>{activity}</Activity>
        <div className="dot">•</div>
        <TimeInfo>{`${date} • ${time}`}</TimeInfo>
      </Infos>
      <ButtonContainer>
        {onAccept && (
          <Button isAccept onClick={onAccept}>
            Finalizar check-in{' '}
            <Icon>
              <CheckIcon />
            </Icon>
          </Button>
        )}
      </ButtonContainer>
    </CardContainer>
  );
};

export default Confirmed;
