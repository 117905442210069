import React from 'react';

export const CloseIcon: React.FC = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 0.5C3.80444 0.5 0 4.30444 0 9C0 13.6956 3.80444 17.5 8.5 17.5C13.1956 17.5 17 13.6956 17 9C17 4.30444 13.1956 0.5 8.5 0.5ZM12.6677 11.2313C12.8288 11.3923 12.8288 11.6528 12.6677 11.8139L11.3105 13.1677C11.1494 13.3288 10.8889 13.3288 10.7278 13.1677L8.5 10.9194L6.26875 13.1677C6.10766 13.3288 5.84718 13.3288 5.68609 13.1677L4.33226 11.8105C4.17117 11.6494 4.17117 11.3889 4.33226 11.2278L6.58065 9L4.33226 6.76875C4.17117 6.60766 4.17117 6.34718 4.33226 6.18609L5.68952 4.82883C5.85061 4.66774 6.11109 4.66774 6.27218 4.82883L8.5 7.08065L10.7313 4.83226C10.8923 4.67117 11.1528 4.67117 11.3139 4.83226L12.6712 6.18952C12.8323 6.35061 12.8323 6.61109 12.6712 6.77218L10.4194 9L12.6677 11.2313Z"
      fill="white"
    />
  </svg>
);

export default CloseIcon;
