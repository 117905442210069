/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, Fragment, useEffect } from 'react';
import { Card } from '@/components/common/Card';
import ModalConfirm from '@/components/common/ModalConfirm';
import ModalAwaitingConfirmation from '@/components/common/ModalAwaitingConfirmation';
import ViewModal from '@/components/common/ModalView';
import ConfirmModal from '@/components/common/ConfirmModal';
import useCardReservationsController from './useCardReservationsByStatusController';
import { Loading } from '@/components/common/Loading';
import { Popup } from '@/components/common/Popup';

import CancellationReasonModal from '@/components/common/CancellationReasonModal';

import useGetCheckins from '@/hooks/useGetCheckins';
import useInvalidateCache from '@/hooks/useInvalidateCache';
import { Status } from '@/types/status';

import { CardsReservationsByStatusProps } from './types';

import {
  Container,
  Title,
  Warning,
  BoxCards,
  ButtonContainer,
  Button,
} from './styles';

export const CardsReservationsByStatus: FC<CardsReservationsByStatusProps> = ({
  title,
  tabsName,
  getIncome,
  innerRef,
  fetchNewCard,
  status,
  endDate,
  startDate,
  isToday,
}) => {
  const { checkins, fetchNextPage, loading, hasNextPage } = useGetCheckins(
    status,
    tabsName,
    isToday,
    startDate,
    endDate
  );

  const { invalidateQuery } = useInvalidateCache();

  const invalidateCheckin = () => {
    invalidateQuery('checkins');
  };

  const {
    openModalCancellation,
    dataModal,
    setOpenModalCancellation,
    setOpenModalCancellationReason,
    openModalCancellationReason,
    handleCancelCheckin,
    isConfirmModal,
    openModalClosure,
    setOpenModalClosure,
    handleDoneCheckin,
    openModalView,
    setOpenModalView,
    openModalConfirmReservation,
    setOpenModalConfirmReservation,
    openAwaitingConfirmation,
    setOpenAwaitingConfirmation,
    isOpenConfirmationModal,
    handleConfirmationModalOpen,
    handleModalCard,
    modalCancelationMessage,
  } = useCardReservationsController({
    invalidateCheckinQuery: invalidateCheckin,
  });

  const totalCheckins = checkins?.length;

  if (loading) {
    return (
      <>
        <Title data-testid="title">
          {title} - <small>{totalCheckins} check-in(s)</small>
        </Title>
        <div
          data-testid="loading-indicator"
          className="table mx-auto my-0 pt-[50px]">
          <Loading borderColor="#fff" width={100} height={100} />
        </div>
      </>
    );
  }

  const showLoadMoreCheckinsButton =
    !fetchNewCard && !loading && checkins.length > 0;

  return (
    <>
      <Popup
        open={openModalCancellation}
        title="Confirmar cancelamento da reserva de:"
        description={modalCancelationMessage ?? ''}
        onClose={setOpenModalCancellation}
        onAction={() => setOpenModalCancellationReason(true)}
        isConfirm={isConfirmModal}
      />
      <CancellationReasonModal
        open={openModalCancellationReason}
        onAction={handleCancelCheckin}
        onClose={setOpenModalCancellationReason}
        checkinId={dataModal?.id}
      />
      <Popup
        open={openModalClosure}
        title="Confirmar encerramento da reserva de:"
        description={dataModal?.name}
        onClose={setOpenModalClosure}
        onAction={() => handleDoneCheckin()}
        isConfirm={isConfirmModal}
      />
      <ViewModal
        open={openModalView}
        onClose={() => setOpenModalView(false)}
        startReservationHour={dataModal?.startReservationHour}
        userData={{
          id: dataModal?.id,
          name: dataModal?.name,
          document: dataModal?.document,
          product: dataModal?.product,
        }}
      />
      <ModalConfirm
        open={openModalConfirmReservation}
        onClose={setOpenModalConfirmReservation}
        startReservationHour={dataModal?.startReservationHour}
        getIncome={getIncome}
        userData={{
          id: dataModal?.id,
          name: dataModal?.name,
          document: dataModal?.document,
          product: dataModal?.product,
        }}
        invalidateCheckinQuery={invalidateCheckin}
      />
      <ModalAwaitingConfirmation
        open={openAwaitingConfirmation}
        onClose={setOpenAwaitingConfirmation}
        startReservationHour={dataModal?.startReservationHour}
        getIncome={getIncome}
        userData={{
          id: dataModal?.id,
          name: dataModal?.name,
          document: dataModal?.document,
          product: dataModal?.product,
        }}
      />
      <ConfirmModal
        open={isOpenConfirmationModal}
        onClose={handleConfirmationModalOpen}
        getIncome={getIncome}
        handleCancel={() => setOpenModalCancellationReason(true)}
        invalidateCheckinQuery={invalidateCheckin}
        status={status as Status}
      />
      <Title data-testid="title">
        {title} - <small>{totalCheckins} check-in(s)</small>
      </Title>
      <Container className="max-h-[500px] overflow-auto">
        {checkins?.length > 0 ? (
          <div>
            <BoxCards className="pt-5">
              {checkins?.map(
                (
                  {
                    status,
                    id,
                    startReservationHour,
                    endReservationHour,
                    user: { name, cpf },
                    product,
                    createdAt,
                  },
                  index
                ) => {
                  if (status === 'REFUNDED') {
                    return <Fragment key={`${name}_${index}`} />;
                  }
                  const isLastReservation = checkins?.length === index + 1;
                  const cardInnerRef = isLastReservation ? innerRef : undefined;

                  return (
                    <Card
                      id={id}
                      name={name}
                      document={cpf}
                      product={product}
                      status={status}
                      startReservationHour={startReservationHour}
                      endReservationHour={endReservationHour}
                      createdAt={createdAt}
                      onOpenModalType={handleModalCard}
                      key={`${name}_${index}`}
                      innerRef={cardInnerRef}
                      handleConfirmationModalOpen={handleConfirmationModalOpen}
                    />
                  );
                }
              )}
            </BoxCards>
            {fetchNewCard ||
              (loading && (
                <div
                  data-testid="loading-indicator"
                  className="table mx-auto my-0 pt-[50px]">
                  <Loading borderColor="#fff" width={100} height={100} />
                </div>
              ))}
          </div>
        ) : (
          <Warning>Você não tem nenhuma reserva!</Warning>
        )}
        {showLoadMoreCheckinsButton && hasNextPage && (
          <ButtonContainer>
            <Button
              onClick={
                fetchNextPage as React.MouseEventHandler<HTMLButtonElement>
              }>
              Carregar mais Check-ins
            </Button>
          </ButtonContainer>
        )}
      </Container>
    </>
  );
};
export default CardsReservationsByStatus;
