import styled from 'styled-components';

export const Icon = styled.span`
  font-size: 24px;
  background-color: #f9f9f9;
  border-radius: 50%;
  width: 59px;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-left: 23px;
  flex-wrap: nowrap;

  @media screen and (max-width: 999px) {
    padding-left: 0;
  }
`;

export const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 10px;
  border-radius: 5px;

  &:hover {
    ${Icon} {
      background-color: #d8f32e;
    }
  }
`;

export const Text = styled.span`
  margin-left: 10px;
  font-size: 16px;
  white-space: nowrap;
  line-height: 22px;
  letter-spacing: -2%;
  font-weight: 500;
  opacity: 0;
  visibility: hidden;
  max-width: 0;
  overflow: hidden;
  transition: opacity 0.1s, visibility 0.1s, max-width 0.1s;

  @media screen and (max-width: 999px) {
    opacity: 1;
    visibility: visible;
    max-width: 200px;
    transition-delay: 0.2s;
  }
`;
