import React, { type FC } from 'react';
import Image from 'next/image';

import useStore from '@/store';
import useUserType from '@/hooks/useUserType';

import { Mobile } from './Mobile';
import Items from './Items';

import { SidebarContainer, Logo } from './styles';

export const SideBarMenu: FC = () => {
  const {
    establishment,
    establishmentGroup,
    loginAs,
    firstAccess,
    updateGetList,
  } = useStore(state => state);
  const { isFranchise, isOwner } = useUserType(loginAs);

  if (!establishment?.id && !establishmentGroup?.id) return <></>;

  return (
    <>
      <Mobile />
      <SidebarContainer>
        <Logo>
          <Image
            src="/Gurupass-Sidebar.svg"
            alt="Gurupass"
            title="Gurupass"
            width={26}
            height={49}
          />
        </Logo>
        <Items
          firstAccess={firstAccess}
          isFranchise={isFranchise}
          isOwner={isOwner}
          updateGetList={updateGetList}
        />
      </SidebarContainer>
    </>
  );
};

export default SideBarMenu;
