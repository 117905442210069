import styled from 'styled-components';

interface ContainerProps {
  hasEstablishment: boolean;
}

export const Container = styled.section<ContainerProps>`
  height: 100%;
  padding: 0 45px;
  background-color: #f8f8f8;
  margin-left: ${({ hasEstablishment }) => (hasEstablishment ? 0 : 126)}px;

  @media screen and (max-width: 1190px) {
    padding: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff3ef;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background: #fff3ef;
  }

  ::-webkit-scrollbar-thumb {
    background: #288319;
  }

  @media screen and (max-width: 998px) {
    height: 100%;
  }

  @media screen and (max-width: 999px) {
    margin-left: 0;
  }
`;
