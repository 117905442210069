import React, { FC } from 'react';

import { Title } from '@/components/common/Title';
import { Home } from '@/components/Home';

export const HomePage: FC = () => (
  <>
    <Title title="Agenda" />
    <Home />
  </>
);

export default HomePage;
