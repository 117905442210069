import styled, { keyframes } from 'styled-components';

interface ContainerProps {
  width?: number;
  height?: number;
  border?: number;
  borderColor?: string;
}

const rotate = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

export const Container = styled.div<ContainerProps>`
  width: ${({ width }) => width || 120}px;
  height: ${({ height }) => height || 120}px;
  border: ${({ border }) => border || 16}px solid
    ${({ borderColor }) => borderColor || '#D8F32E'};
  border-top: ${({ border }) => border || 16}px solid rgba(216, 243, 46, 0.6);
  border-radius: 50%;
  animation: ${rotate} 2s linear infinite;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 5%);
`;
