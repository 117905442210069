import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html,
body {
	max-width: 100vw;
  height: 100%;
	overflow-x: hidden;
}

body {
	background-color: #f8f8f8;
}

a {
	color: inherit;
	text-decoration: none;
}

@font-face {
	font-family: Eina;
	src: local('fonts/Eina-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: Eina;
	src: local('fonts/Eina-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: Graphik;
	src: local('fonts/graphik/Graphik-LightItalic.woff2') format('woff2'),
		local('fonts/graphik/Graphik-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: Graphik;
	src: local('fonts/graphik/Graphik-Light.woff2') format('woff2'),
		local('fonts/graphik/Graphik-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: Graphik;
	src: local('fonts/graphik/Graphik-MediumItalic.woff2') format('woff2'),
		local('fonts/graphik/Graphik-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: Graphik;
	src: local('fonts/graphik/Graphik-Medium.woff2') format('woff2'),
		local('fonts/graphik/Graphik-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: Graphik;
	src: local('fonts/graphik/Graphik-Regular.woff2') format('woff2'),
		local('fonts/graphik/Graphik-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: Graphik;
	src: local('fonts/graphik/Graphik-RegularItalic.woff2') format('woff2'),
		local('fonts/graphik/Graphik-RegularItalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: Graphik;
	src: local('fonts/graphik/Graphik-SemiboldItalic.woff2') format('woff2'),
		local('fonts/graphik/Graphik-SemiboldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: Graphik;
	src: local('fonts/graphik/Graphik-Semibold.woff2') format('woff2'),
		local('fonts/graphik/Graphik-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: block;
}
`;

export default GlobalStyle;
