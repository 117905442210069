const getItemStorage = (key: string) => {
  if (global?.window) {
    return localStorage.getItem(key);
  }
};

const setItemStorage = async (key: string, item: string) => {
  if (global?.window) {
    localStorage.setItem(key, item);
  }
};

const clearStorage = async (key: string) => {
  if (global?.window) {
    localStorage.removeItem(key);
  }
};

export { getItemStorage, setItemStorage, clearStorage };
