import { format } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';

class DateHelper {
  private date: Date;

  constructor(date: Date | string) {
    this.date = new Date(date);
  }

  public getToday(): Date {
    return new Date();
  }

  public formatBr(): string {
    if (this.date) {
      return this.date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    }
    return '';
  }

  public formatTime(): string {
    if (this.date) {
      return format(this.date, 'HH:mm', { locale: locale as Locale });
    }
    return '';
  }

  public formatDateWithHours(): string {
    if (this.date) {
      const dateString = this.date.toLocaleDateString('pt-BR');
      const timeString = this.date.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return `${dateString} às ${timeString}`;
    }
    return '';
  }

  public formatDate = (isYearIncluded = false): string => {
    if (!this.date) return '';

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: isYearIncluded ? 'numeric' : undefined,
    };

    return this.date.toLocaleDateString('pt-BR', options);
  };

  public formatDateMonth = (): string => {
    if (!this.date) return '';

    const day = String(this.date.getDate()).padStart(2, '0');
    const monthNames = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    const month = monthNames[this.date.getMonth()];

    return `${day} de ${month}`;
  };
}

export default DateHelper;
