import React, { type FC } from 'react';
import useStore from '@/store';

import { FaExclamationCircle } from 'react-icons/fa';

import { AlertProps } from './types';
import { Container, Content, Text, Subtitle } from './styles';

export const Alert: FC<AlertProps> = ({ width }) => {
  const { alertModal, updateAlertModal } = useStore();

  return (
    <Container onClick={() => updateAlertModal(!alertModal)} width={width}>
      <FaExclamationCircle size={39} color="#D8F32E" />
      <Content>
        <Text>Alerta!</Text>
        <Subtitle>Clique para ver comunicado.</Subtitle>
      </Content>
    </Container>
  );
};

export default Alert;
