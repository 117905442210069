import React, { FC, Fragment } from 'react';

import { Card } from '@/components/common/Card';
import ModalConfirm from '@/components/common/ModalConfirm';
import ModalAwaitingConfirmation from '@/components/common/ModalAwaitingConfirmation';
import ViewModal from '@/components/common/ModalView';
import ConfirmModal from '@/components/common/ConfirmModal';
import useCardReservationsController from './useCardReservationsController';
import { Loading } from '@/components/common/Loading';
import { Popup } from '@/components/common/Popup';

import { Status } from '@/types/status';
import { CardsReservationsProps } from './types';

import { Container, Title, Warning, BoxCards } from './styles';

import CancellationReasonModal from '../common/CancellationReasonModal';
import useInvalidateCache from '@/hooks/useInvalidateCache';

export const CardsReservations: FC<CardsReservationsProps> = ({
  title,
  data,
  time,
  getIncome,
  innerRef,
  fetchNewCard,
}) => {
  const { invalidateQuery } = useInvalidateCache();

  const invalidateCheckin = () => {
    invalidateQuery('checkins');
  };

  const {
    openModalCancellation,
    dataModal,
    setOpenModalCancellation,
    setOpenModalCancellationReason,
    openModalCancellationReason,
    handleCancelCheckin,
    isConfirmModal,
    openModalClosure,
    setOpenModalClosure,
    handleDoneCheckin,
    openModalView,
    setOpenModalView,
    openModalConfirmReservation,
    setOpenModalConfirmReservation,
    openAwaitingConfirmation,
    setOpenAwaitingConfirmation,
    isOpenConfirmationModal,
    handleConfirmationModalOpen,
    dataReservations,
    handleModalCard,
    modalCancelationMessage,
    haveReserveCard,
    reservationsCount,
    totalCheckins,
  } = useCardReservationsController({
    data,
    time,
  });

  if (data?.length <= 0) {
    return <></>;
  }

  return (
    <>
      <Popup
        open={openModalCancellation}
        title="Confirmar cancelamento da reserva de:"
        description={modalCancelationMessage ?? ''}
        onClose={setOpenModalCancellation}
        onAction={() => setOpenModalCancellationReason(true)}
        isConfirm={isConfirmModal}
      />
      <CancellationReasonModal
        open={openModalCancellationReason}
        onAction={handleCancelCheckin}
        onClose={setOpenModalCancellationReason}
        checkinId={dataModal?.id}
      />
      <Popup
        open={openModalClosure}
        title="Confirmar encerramento da reserva de:"
        description={dataModal?.name}
        onClose={setOpenModalClosure}
        onAction={() => handleDoneCheckin()}
        isConfirm={isConfirmModal}
      />
      <ViewModal
        open={openModalView}
        onClose={() => setOpenModalView(false)}
        startReservationHour={dataModal?.startReservationHour}
        userData={{
          id: dataModal?.id,
          name: dataModal?.name,
          document: dataModal?.document,
          product: dataModal?.product,
        }}
      />
      <ModalConfirm
        open={openModalConfirmReservation}
        onClose={setOpenModalConfirmReservation}
        startReservationHour={dataModal?.startReservationHour}
        getIncome={getIncome}
        userData={{
          id: dataModal?.id,
          name: dataModal?.name,
          document: dataModal?.document,
          product: dataModal?.product,
        }}
        invalidateCheckinQuery={invalidateCheckin}
      />
      <ModalAwaitingConfirmation
        open={openAwaitingConfirmation}
        onClose={setOpenAwaitingConfirmation}
        startReservationHour={dataModal?.startReservationHour}
        getIncome={getIncome}
        userData={{
          id: dataModal?.id,
          name: dataModal?.name,
          document: dataModal?.document,
          product: dataModal?.product,
        }}
      />
      <ConfirmModal
        open={isOpenConfirmationModal}
        onClose={handleConfirmationModalOpen}
        getIncome={getIncome}
        handleCancel={() => setOpenModalCancellationReason(true)}
        invalidateCheckinQuery={invalidateCheckin}
        status={status as Status}
      />
      <Container>
        <Title data-testid="title">
          {title} - <small>{totalCheckins} check-in(s)</small>
        </Title>
        {haveReserveCard ? (
          <BoxCards>
            {dataReservations?.map(
              (
                {
                  status,
                  id,
                  startReservationHour,
                  endReservationHour,
                  user: { name, cpf },
                  product,
                  createdAt,
                },
                index
              ) => {
                if (status === 'REFUNDED') {
                  return <Fragment key={`${name}_${index}`} />;
                }
                const isLastReservation = reservationsCount === index + 1;
                const cardInnerRef = isLastReservation ? innerRef : undefined;

                return (
                  <Card
                    id={id}
                    name={name}
                    document={cpf}
                    product={product}
                    status={status}
                    startReservationHour={startReservationHour}
                    endReservationHour={endReservationHour}
                    createdAt={createdAt}
                    onOpenModalType={handleModalCard}
                    key={`${name}_${index}`}
                    innerRef={cardInnerRef}
                    handleConfirmationModalOpen={handleConfirmationModalOpen}
                  />
                );
              }
            )}
            {fetchNewCard && (
              <div
                data-testid="loading-indicator"
                className="table mx-auto my-0 pt-[50px]">
                <Loading borderColor="#fff" width={100} height={100} />
              </div>
            )}
          </BoxCards>
        ) : (
          <Warning>Você não tem nenhuma reserva!</Warning>
        )}
      </Container>
    </>
  );
};
export default CardsReservations;
