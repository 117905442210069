import styled from 'styled-components';

export const Container = styled.section`
  margin-bottom: 50px;

  div.slider-pagination {
    .swiper {
      height: 290px !important;
    }
    .swiper-button-next,
    .swiper-button-prev {
      color: #434343 !important;
      top: 40% !important;
    }
    .swiper-pagination-bullet-active {
      background-color: #434343 !important;
    }
    .swiper-pagination-bullet {
      width: 15px !important;
      height: 15px !important;
    }
  }
`;

export const Title = styled.h2`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #434343;
  margin-bottom: 21px;
`;

export const Warning = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #838383;
`;

export const BoxCards = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
