import styled from 'styled-components';

export const SidebarContainer = styled.div`
  width: 126px;
  transition: width 0.3s;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100vh;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 99;

  &:hover {
    width: 313px;

    .items {
      opacity: 1;
      visibility: visible;
      max-width: 200px;
      transition-delay: 0.2s;
    }
  }

  a {
    width: 100%;
    text-decoration: none;
  }

  @media screen and (max-width: 999px) {
    display: none;
  }
`;

export const Logo = styled.div`
  margin: 28px;
  padding-left: 23px;
`;
