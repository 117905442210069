import React, { FC, useCallback, useEffect, useState } from 'react';

import { Template } from '@/components/common/Template';
import { ListReservations } from '@/components/ListReservations';

import { IncomeSection } from '../common/IncomeSection';
import { clientPost } from '@/services/client';
import { apiEndpoints } from '@/config/endpoints';
import { Income } from '@/types/income';
import useStore from '@/store';
import useRedirectIfFranchisee from '@/hooks/useRedirectIfFranchisee';

export const Home: FC = () => {
  useRedirectIfFranchisee();

  const actualMonth = new Date().toLocaleString('default', {
    month: '2-digit',
  });
  const [income, setIncome] = useState<Income>();
  const [month, setMonth] = useState(Number(actualMonth));
  const [year, setYear] = useState(new Date().getFullYear());

  const { establishment } = useStore(state => state);

  const getIncome = useCallback(async () => {
    if (!establishment) return;

    const response = await clientPost(
      apiEndpoints.getIncome(establishment?.id),
      {
        month,
        year,
      }
    );

    setIncome(response || []);
  }, [establishment, month, year]);

  useEffect(() => {
    getIncome();
  }, [getIncome]);

  return (
    <Template>
      <section>
        <div className="w-full flex items-start justify-between gap-[60px] flex-col lg:flex-row">
          <div className="w-[100%]">
            <ListReservations isHome={true} getIncome={getIncome} />
          </div>
          <div className="w-[100%] lg:w-[45%]" data-testid="income-section">
            <IncomeSection
              month={month}
              setMonth={setMonth}
              year={year}
              setYear={setYear}
              income={income}
            />
          </div>
        </div>
      </section>
    </Template>
  );
};
