import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 28px;
  margin-bottom: 24px;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    justify-content: flex-start;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: right;
  gap: 10px;
  width: 100%;
  margin-bottom: 15px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FilterButton = styled.button`
  padding: 9px 12px;
  height: 42px;
  background-color: #e1ff2b;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: 'Graphik', sans-serif;
  font-weight: 500;
  line-height: 15px;
  color: #000;
  transition: opacity 0.2s, box-shadow 0.2s;

  &:hover {
    opacity: 0.8;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ButtonText = styled.p`
  color: #000;
`;

export const DateRangeText = styled.span`
  color: #6b7280;
`;

export const DatePickerContainer = styled.div`
  position: absolute;
  z-index: 10;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  margin-top: -15px;

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__day--keyboard-selected {
    background-color: #d5ec40;
    color: white;
  }

  .react-datepicker__day:hover {
    background-color: #d8f32e;
  }
`;

export const ConfirmButton = styled.button`
  margin-top: 8px;
  padding: 10px;
  width: 100%;
  background-color: #e1ff2b;
  color: #000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;

  &:hover {
    background-color: #d5ec40;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
