import styled from 'styled-components';

interface ReserveProps {
  background?: boolean;
}

export const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

export const ContentModal = styled.div`
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  padding: 16px;
  border: 7px solid rgba(255, 255, 255, 0.3);
  box-shadow: 4px 4px 44px rgba(0, 0, 0, 0.06);
  width: 600px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .close {
    cursor: pointer;
  }

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

export const Infos = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

export const Name = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 21px;
  color: #434343;
  margin-bottom: 10px;

  @media screen and (max-width: 450px) {
    text-align: center;
  }
`;

export const Document = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #838383;

  @media screen and (max-width: 450px) {
    text-align: center;
  }
`;

export const Content = styled.div`
  border-top: 1px solid rgba(214, 214, 214, 0.8);
  border-bottom: 1px solid rgba(214, 214, 214, 0.8);
  padding: 26px 0;
  display: flex;
  align-items: center;
  gap: 60px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
`;

export const Reserve = styled.p<ReserveProps>`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #838383;
  ${({ background }) => background && 'background: rgba(214, 214, 214, 0.2);'}
  padding: 8px 16px;
  border-radius: 20px;

  span {
    font-weight: bold;
  }
`;

export const BottomContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
`;

export const Tag = styled.div`
  min-height: 40px;
  background: rgba(214, 214, 214, 0.2);
  border-radius: 500px;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 20px;

  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #949494;

  span {
    font-family: 'Graphik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #949494;
  }
`;

export const Description = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #838383;
  margin: 16px 0;
`;

export const Input = styled.div`
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CodeNumber = styled.input`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #4a4a4a;
  width: 211px;
  height: 50px;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  padding: 15px;
  position: relative;
  outline: 0;

  &:focus {
    border: 1px solid #dfdfdf;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const IconInput = styled.div`
  position: absolute;
  left: 155px;
  top: 5px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    position: absolute;
    right: 20px;
    left: initial;
    top: 5px;
    cursor: pointer;
  }
`;

export const ButtonClose = styled.div`
  @media screen and (max-width: 450px) {
    position: absolute;
    right: 50px;
  }
`;
