import styled from 'styled-components';

export const NotificationContainer = styled.div`
  display: flex;
  min-height: 5rem;
  height: auto;
  align-items: center;
  justify-content: space-between;
  border-radius: 2rem;
  background-color: white;
  gap: 10px;
  padding: 1rem 1.6rem;
  right: 15px;

  @media screen and (max-width: 500px) {
    gap: 10px;
    height: auto;
  }
`;

export const CloseContainer = styled.div`
  cursor: pointer;
  padding-top: 0.2rem;
`;

export const MainText = styled.h4`
  font-family: 'graphik', sans-serif;
  font-weight: 400;
  color: #333333;

  @media screen and (max-width: 400px) {
    font-size: 0.8em;
  }
`;

export const LinkText = styled.a`
  font-size: 1rem;
  color: #888888;
`;

export const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;
