import React, { type FC, useState } from 'react';
import Image from 'next/image';
import { FaCheck } from 'react-icons/fa';
import { HiInformationCircle } from 'react-icons/hi2';

import { useIndicationCode } from '@/components/Partner/hooks/useIndicationCode';

import { handleClipboardClick } from '@/utils/handleClipboardClick';
import { shareFrowWhatsApp } from '@/utils/shareFrowWhatsApp';

export const IndicationCodeForUsers: FC = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const { indicationCode } = useIndicationCode();

  const handleGoIndicationPage = () => {
    window.location.href = '/indicar';
  };

  return (
    <div className="mt-10 mb-10">
      <div
        className="bg-[rgba(255,255,255,0.6)] rounded-t-xl rounded-b-none overflow-hidden p-5 flex gap-3 cursor-pointer"
        onClick={() => handleGoIndicationPage()}>
        <HiInformationCircle color="#D8F42E" size={70} />
        <div className="flex flex-col">
          <div className="font-semibold sm:text-[18px] items-center flex">
            Código de indicação para usuários
          </div>
          <div className="font-normal sm:text-[14px] items-center flex mt-2 mb-2">
            Agora você pode indicar usuários e receber benefícios exclusivos.
          </div>
        </div>
      </div>
      <div className="bg-main-green-500 rounded-b-xl rounded-t-none overflow-hidden p-5">
        <div className="font-semibold text-1xl sm:text-3xl items-center flex">
          {indicationCode}
        </div>
        <div className="flex gap-4 mt-5">
          <button
            onClick={() => handleClipboardClick(indicationCode, setIsAnimating)}
            className={`bg-white rounded-[10px] p-[12px] h-12 w-[100%] flex items-center justify-center ${
              isAnimating ? 'animate-clipboardToCheck' : ''
            }`}>
            {isAnimating ? (
              <FaCheck className="h-8 w-8" />
            ) : (
              <div className="flex gap-2">
                <Image
                  src="/clipboard.svg"
                  alt="clipboard"
                  width={19}
                  height={19}
                />
                <p className="hidden sm:block text-[12px]">Copiar código</p>
              </div>
            )}
          </button>
          <button
            className={`bg-white rounded-[10px] p-[12px] w-[100%] h-12 flex items-center justify-center ${
              isAnimating ? 'animate-clipboardToCheck' : ''
            }`}
            onClick={() => shareFrowWhatsApp(indicationCode)}>
            <div className="flex gap-2">
              <Image src="/share.svg" alt="share" width={20} height={20} />
              <p className="hidden sm:block text-[12px]">Compartilhar</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default IndicationCodeForUsers;
