import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useStore } from '@/store';

import { clearStorage } from '@/services/storage';

import { useUserType } from '@/hooks/useUserType';
import useSocket from '@/hooks/webSocket';

const useHeadingController = () => {
  const router = useRouter();

  const {
    establishment,
    establishmentGroup,
    name,
    photo,
    updateGetList,
    updateEstablishment,
    loginAs,
  } = useStore(state => state);
  const { isFranchise, isOwner } = useUserType(loginAs);

  const webSocket = useSocket(establishment?.id as string);

  const [notificationMessage, setNotificationMessage] = useState({
    title: '',
    message: '',
  });

  const navigation = (path: string) => {
    router.push(path);
  };

  const signOut = () => {
    updateGetList(false);
    clearStorage('daypass_access_token');
    clearStorage('daypass-user');
    updateEstablishment(null);

    router.push('/login');
  };

  const cleanNotification = () => {
    setNotificationMessage({ message: '', title: '' });
  };

  const clearNotifications = useCallback(() => {
    if (notificationMessage?.title) {
      const timeoutId = setTimeout(cleanNotification, 6000);

      return () => clearTimeout(timeoutId);
    }
  }, [notificationMessage]);

  useEffect(() => {
    if (webSocket.dataEvent) {
      setNotificationMessage(webSocket.dataEvent);
    }
  }, [webSocket.dataEvent]);

  useEffect(() => {
    clearNotifications();
  }, [notificationMessage?.title]);

  function addToFavorite() {
    alert(
      'Aperte ' +
        (navigator.userAgent.toLowerCase().indexOf('mac') != -1
          ? 'Cmd'
          : 'Ctrl') +
        '+D para favoritar essa página.'
    );
  }

  return {
    establishment,
    establishmentGroup,
    navigation,
    router,
    photo,
    name,
    notificationMessage,
    setNotificationMessage,
    isFranchise,
    addToFavorite,
    isOwner,
  };
};

export default useHeadingController;
