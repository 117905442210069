export const tabsItems = [
  {
    name: 'Todas',
    value: 'ALL',
  },
  {
    name: 'Pendentes',
    value: 'PENDING',
  },
  {
    name: 'Ativas',
    value: 'CONFIRMED',
  },
  {
    name: 'Finalizadas',
    value: 'DONE',
  },
];
