import { useState, useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

import { useCheckinStore } from '@/store/checkin';
import useStore from '@/store';

import { ListReservationsReturnTypes } from './types';
import { CardsReservationsData } from '@/components/CardsReservations/types';

export const useListReservationsController =
  (): ListReservationsReturnTypes => {
    const { ref, inView } = useInView();

    const { establishment, role, getList, updateGetList } = useStore(
      state => state
    );
    const { checkin, handleChangeRefreshCheckin, handleUpdateCheckin } =
      useCheckinStore();

    const [tabsName, setTabName] = useState<string>('ALL');
    const [dataCheckins, setDataCheckins] = useState<CardsReservationsData[]>(
      []
    );

    const onChangeTabs = (name: string) => {
      if (!establishment?.id) return;
      setTabName(name);
    };

    const updateDataCheckins = async () => {
      const isInvalidCheckinData =
        !dataCheckins || dataCheckins.length === 0 || checkin === null;

      if (isInvalidCheckinData) {
        return;
      }

      const isCheckinAlreadyPresent = dataCheckins.some(
        ({ id }) => id === checkin.id
      );

      if (!isCheckinAlreadyPresent) {
        setDataCheckins([checkin, ...dataCheckins]);
      } else {
        const updatedCheckins = dataCheckins.map(item =>
          item.id === checkin.id ? checkin : item
        );
        setDataCheckins(updatedCheckins);
      }

      handleChangeRefreshCheckin(false);
      handleUpdateCheckin(null);
    };

    useEffect(() => {
      updateDataCheckins();
    }, [checkin, updateDataCheckins]);

    return {
      tabsName,
      onChangeTabs,
      role,
      ref,
    };
  };

export default useListReservationsController;
