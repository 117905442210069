import React, { FC } from 'react';

import {
  Container,
  ContentModal,
  Header,
  Name,
  Document,
  Content,
  Reserve,
  Infos,
  CloseButton,
} from './styles';

import { AiOutlineClose } from 'react-icons/ai';
import { FaUserCircle } from 'react-icons/fa';
import { Product } from '@/types/product';
import DateHelper from '@/helpers/dateHelper';

interface UserData {
  id: string;
  name: string;
  document: string;
  product: Product;
}

interface ViewModalProps {
  userData: UserData;
  open?: boolean;
  onClose?: (show: boolean) => void;
  startReservationHour: string;
}

export const ViewModal: FC<ViewModalProps> = ({
  userData,
  open,
  onClose,
  startReservationHour,
}) => {
  const dateHelper = new DateHelper(startReservationHour);
  const reservationDate = dateHelper.formatDate();

  const handleClose = () => onClose && onClose(false);

  if (!open) {
    return null;
  }

  return (
    <Container onClick={handleClose}>
      <ContentModal onClick={e => e.stopPropagation()}>
        <Header>
          <Infos>
            <FaUserCircle color="#D8F32E" size={54} />
            <div>
              <Name>{userData.name}</Name>
              <Document>
                Reserva para o dia <span>{reservationDate}</span>
              </Document>
            </div>
          </Infos>

          <CloseButton>
            <AiOutlineClose
              size={30}
              color="#818181"
              className="close"
              onClick={handleClose}
            />
          </CloseButton>
        </Header>
        <Content showButtonConfirm>
          <Reserve>
            {userData.name} fez a reserva para às{' '}
            <span>{startReservationHour && dateHelper.formatTime()}</span> do
            dia{' '}
            <span>{startReservationHour && dateHelper.formatDate(true)}</span>
          </Reserve>
          <Reserve background>
            <span>{userData.product.name}</span>
          </Reserve>
        </Content>
      </ContentModal>
    </Container>
  );
};

export default ViewModal;
