import React, { FC } from 'react';

import { Container } from './styles';

export interface TabsProps {
  text: string;
  active: boolean;
  onClick: () => void;
}

export const Tabs: FC<TabsProps> = ({ text, active, onClick }) => {
  return (
    <Container active={active} onClick={onClick}>
      {text}
    </Container>
  );
};

export default Tabs;
