import React from 'react';
import CardsReservationsByStatus from '../CardsReservationsByStatus';
import CardsReservations from '@/components/CardsReservations';
import { CardsReservationsData } from '@/components/CardsReservations/types';

interface ShowNextCheckinsProps {
  getIncome: (() => void) | undefined;
  ref: (node?: Element | null | undefined) => void;
  checkins: CardsReservationsData[];
}

const ShowNextCheckins = ({
  getIncome,
  ref,
  checkins,
}: ShowNextCheckinsProps) => {
  return (
    <>
      <CardsReservations
        title="Suas reservas de hoje"
        data={checkins}
        time={'today'}
        getIncome={getIncome}
        innerRef={ref}
      />
      <CardsReservations
        title="Suas reservas futuras"
        data={checkins}
        showButtonConfirm={false}
        time={'future'}
        getIncome={getIncome}
        innerRef={ref}
      />
      <CardsReservations
        title="Suas reservas de ontem"
        data={checkins}
        time={'yesterday'}
        getIncome={getIncome}
        innerRef={ref}
      />
      <CardsReservations
        title="Suas reservas dessa semana"
        data={checkins}
        time={'week'}
        getIncome={getIncome}
        innerRef={ref}
      />
    </>
  );
};

export default ShowNextCheckins;
