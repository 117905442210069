import React, { FC, useEffect, useCallback } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FaUserCircle } from 'react-icons/fa';
import { ImCross, ImCheckmark } from 'react-icons/im';

import { useConfirmationModalStore } from '@/store/modal/ConfirmationModal/ConfirmationModal';
import DateHelper from '@/helpers/dateHelper';
import useStore from '@/store';

import { Product } from '@/types/product';

import {
  Container,
  ContentModal,
  Header,
  Name,
  Document,
  Description,
  DescriptionText,
  Tag,
  Infos,
  ContentTitle,
  Title,
  ButtonClose,
  IconsContent,
  Icon,
} from './styles';

interface UserData {
  id: string;
  name: string;
  document: string;
  product: Product;
}

interface ModalAwaitingConfirmationProps {
  userData: UserData;
  open?: boolean;
  onClose: (show: boolean) => void;
  startReservationHour: string;
  getIncome?: () => void;
}

export const ModalAwaitingConfirmation: FC<ModalAwaitingConfirmationProps> = ({
  userData,
  open,
  onClose,
  startReservationHour,
}) => {
  const {
    handleSetClientName,
    handleConfirmationModalOpen,
    handleSetDate,
    handleSetIsConfirm,
    handleSetGymClassName,
    handleSetUserId,
  } = useConfirmationModalStore();

  const actualDate = startReservationHour;
  const dateHelper = new DateHelper(actualDate);
  const reservationDate = dateHelper.formatDateWithHours();
  const { updateGetList } = useStore(state => state);

  const handleClose = () => onClose && onClose(false);

  const setUserAndCheckinData = useCallback(async () => {
    handleSetUserId(userData?.id);
    handleSetDate(new Date(startReservationHour));
    handleSetGymClassName(userData?.product?.name);
    handleSetClientName(userData?.name);
  }, [
    handleSetClientName,
    handleSetDate,
    handleSetGymClassName,
    handleSetUserId,
    startReservationHour,
    userData?.id,
    userData?.name,
    userData?.product?.name,
  ]);

  const handleConfirmCheckin = (isConfirm: boolean) => {
    onClose(false);
    handleConfirmationModalOpen(true);
    handleSetIsConfirm(isConfirm);
  };

  useEffect(() => {
    open && setUserAndCheckinData();
  }, [open, setUserAndCheckinData]);

  const userName = userData?.name;
  const productName = userData?.product?.name;

  if (!open) {
    return null;
  }

  return (
    <Container onClick={handleClose}>
      <ContentModal onClick={e => e.stopPropagation()}>
        <ContentTitle>
          <Title>
            <strong>Aguardando Confirmação</strong>
          </Title>
          <ButtonClose onClick={handleClose} data-testid="close-button">
            <AiOutlineClose size={30} color="#818181" className="close" />
          </ButtonClose>
        </ContentTitle>
        <Header>
          <Infos>
            <FaUserCircle color="#D8F32E" size={54} />
            <div>
              <Name>{userName}</Name>
              <Document>
                Reserva para o dia{' '}
                <span data-testid="reservation-date">{reservationDate}</span>
              </Document>
            </div>
          </Infos>
        </Header>

        <Description>
          <DescriptionText>
            {userName} deseja fazer uma aula de{' '}
            <strong data-testid="product-name">{productName}</strong> às{' '}
            <strong>{dateHelper.formatTime()}</strong> do dia{' '}
            <strong>{dateHelper.formatDateMonth()}</strong>
          </DescriptionText>
          <Tag>{productName}</Tag>
        </Description>

        <IconsContent>
          <Icon bgColor="#D8F32E" onClick={() => handleConfirmCheckin(true)}>
            <ImCheckmark color="#fff" size={20} />
          </Icon>
          <Icon bgColor="#606060" onClick={() => handleConfirmCheckin(false)}>
            <ImCross color="#fff" size={20} />
          </Icon>
        </IconsContent>
      </ContentModal>
    </Container>
  );
};

export default ModalAwaitingConfirmation;
