import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { ToastContainer, toast } from 'react-toastify';

import type { AppProps } from 'next/app';
import NProgress from 'nprogress';

import GlobalStyle from '@/styles/globals';
import '@/styles/nprogress.css';
import '@/styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import useStore from '@/store';
import { useUserType } from '@/hooks/useUserType';

import { SideBarMenu } from '@/components/common/SideBarMenu';

import { unauthenticatedRoutes } from '@/config/unatheticatedRoutes';

const queryClient = new QueryClient();

export const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const [preloader, setPreloader] = useState(true);

  const { establishment, firstAccess, userUuid, loginAs } = useStore(
    state => state
  );
  const { isFranchise } = useUserType(loginAs);

  const checkFirstAccess = async () => {
    const routesToBypass = ['/login', '/first-access'];
    if (userUuid && firstAccess && !routesToBypass.includes(router.pathname)) {
      toast.info('É necessário alterar sua senha para continuar.');
      return await router.push('/first-access');
    }
  };

  const sendToChoiceIfHaventSelectedOne = () => {
    if (userUuid && !establishment) router.push('/escolha');
  };

  useEffect(() => {
    const handleLoading = () => NProgress.start();
    const handleLoaded = () => NProgress.done();

    router.events.on('routeChangeStart', handleLoading);
    router.events.on('routeChangeComplete', handleLoaded);
    router.events.on('routeChangeError', handleLoaded);

    return () => {
      router.events.off('routeChangeStart', handleLoading);
      router.events.off('routeChangeComplete', handleLoaded);
      router.events.off('routeChangeError', handleLoaded);
    };
  }, [router]);

  const onLoad = useCallback(() => {
    if (global?.window) {
      setPreloader(false);
    }
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    const isUnauthenticatedRoute = unauthenticatedRoutes.includes(
      router.pathname
    );

    if (!establishment && !isUnauthenticatedRoute && !isFranchise) {
      checkFirstAccess();
    }
  }, [establishment, router, isFranchise]);

  useEffect(() => {
    sendToChoiceIfHaventSelectedOne();
  }, [establishment]);

  if (preloader) {
    return <Fragment />;
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        <SideBarMenu />
        <Component {...pageProps} />
        <ToastContainer />
      </QueryClientProvider>
    </>
  );
};

export default App;
