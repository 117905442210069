import styled from 'styled-components';

interface IconProps {
  bgColor?: string;
}

export const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

export const ContentModal = styled.div`
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  padding: 16px;
  width: auto;
  border: 7px solid rgba(255, 255, 255, 0.3);
  box-shadow: 4px 4px 44px rgba(0, 0, 0, 0.06);

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .close {
    cursor: pointer;
  }
`;

export const Infos = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
`;

export const Name = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 21px;
  color: #434343;
  margin-bottom: 10px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const Document = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #838383;
`;

export const Tag = styled.div`
  min-height: 40px;
  background: rgba(214, 214, 214, 0.2);
  border-radius: 500px;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 20px;

  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #949494;

  span {
    font-family: 'Graphik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #949494;
  }
`;

export const Description = styled.div`
  border-top: 1px solid rgba(214, 214, 214, 0.8);
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DescriptionText = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #838383;
  margin: 16px 0;
  max-width: 400px;
  width: 100%;
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(214, 214, 214, 0.8);
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
`;

export const Title = styled.p`
  text-align: center;
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 21px;
  color: #434343;
  align-self: center;

  @media screen and (max-width: 768px) {
    margin-top: 40px;
  }
`;

export const ButtonClose = styled.div`
  @media screen and (min-width: 408px) {
    position: absolute;
    top: -10px;
    right: 10px;
    cursor: pointer;
  }
`;

export const IconsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
`;

export const Icon = styled.div<IconProps>`
  background-color: ${({ bgColor }) => bgColor};
  width: 41px;
  height: 41px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
