export const Months = [
  { label: 'Janeiro', value: 1 },
  { label: 'Fevereiro', value: 2 },
  { label: 'Março', value: 3 },
  { label: 'Abril', value: 4 },
  { label: 'Maio', value: 5 },
  { label: 'Junho', value: 6 },
  { label: 'Julho', value: 7 },
  { label: 'Agosto', value: 8 },
  { label: 'Setembro', value: 9 },
  { label: 'Outubro', value: 10 },
  { label: 'Novembro', value: 11 },
  { label: 'Dezembro', value: 12 },
];

const years = new Array(10).fill(0).map((_, index) => {
  const year = new Date().getFullYear() - index;
  if (year < 2023) return;

  return {
    value: year,
    label: year.toString(),
  };
});

export const yearsOptions = years.filter(year => year != undefined) as {
  value: number;
  label: string;
}[];
