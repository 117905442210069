import { CardsReservationsData } from '@/components/CardsReservations/types';
import { create } from 'zustand';

interface Checkin {
  refreshCheckin: boolean;
  handleChangeRefreshCheckin: (refreshCheckin: boolean) => void;
  checkin: CardsReservationsData | null;
  handleUpdateCheckin: (checkin: CardsReservationsData | null) => void;
}

const initialState = {
  refreshCheckin: false,
  checkin: null,
};

const useCheckinStore = create<Checkin>(set => ({
  ...initialState,
  handleChangeRefreshCheckin: refreshCheckin => set({ refreshCheckin }),
  handleUpdateCheckin: checkin => set({ checkin }),
}));

export { useCheckinStore };
