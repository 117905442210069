import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { Establishment } from '@/types/establishment';
import { LoginAs } from '@/types/loginAs';
import { Neighborhood } from '@/types/neighborhood';
import { Worker } from '@/types/worker';

interface State {
  role: Worker | null;
  userUuid: string;
  token: string;
  photo: string;
  name: string;
  getList: boolean;
  establishment: Establishment | null;
  establishmentGroup: Establishment | null;
  numberOfEstablishments: number;
  neighborhoods: Neighborhood[];
  updateRole: (role: Worker | null) => void;
  firstAccess: boolean;
  workerName: string;
  alertModal: boolean;
  loginAs: LoginAs;
  updateUserUuid: (userUuid: string) => void;
  updateUserPhoto: (photo: string) => void;
  updateUserName: (name: string) => void;
  updateGetList: (update: boolean) => void;
  updateEstablishment: (establishment: Establishment | null) => void;
  updateEstablishmentGroup: (establishmentGroup: Establishment | null) => void;
  updateNeighborhoods: (neighborhood: Neighborhood[]) => void;
  updateFirstAccess: (firstAccess: boolean) => void;
  updateWorkerName: (workerName: string) => void;
  updateAlertModal: (alertModal: boolean) => void;
  updateLoginAs: (loginAs: LoginAs) => void;
  updateNumberOfEstablishments: (numberOfEstablishments: number) => void;
}

const persistConfig = {
  name: 'daypass-user',
  partialize: (state: State) => ({
    userUuid: state.userUuid,
    token: state.token,
    photo: state.photo,
    name: state.name,
    establishment: state.establishment,
    establishmentGroup: state.establishmentGroup,
    firstAccess: state.firstAccess,
    workerName: state.workerName,
    role: state.role,
    alertModal: state.alertModal,
    loginAs: state.loginAs,
    numberOfEstablishments: state.numberOfEstablishments,
  }),
  merge: (persistedState: any, currentState: State) => ({
    ...currentState,
    userUuid: persistedState.userUuid,
    token: persistedState.token,
    photo: persistedState.photo,
    name: persistedState.name,
    establishment: persistedState.establishment,
    establishmentGroup: persistedState.establishmentGroup,
    firstAccess: persistedState.firstAccess,
    workerName: persistedState.workerName,
    role: persistedState.role,
    alertModal: persistedState.alertModal,
    loginAs: persistedState.loginAs,
    numberOfEstablishments: persistedState.numberOfEstablishments,
  }),
};

export const useStore = create<State>()(
  devtools(
    persist(
      (set, get) => ({
        role: get()?.role,
        userUuid: get()?.userUuid,
        token: get()?.token,
        photo: get()?.photo,
        name: get()?.name,
        getList: get()?.getList,
        establishment: get()?.establishment,
        establishmentGroup: get()?.establishmentGroup,
        neighborhoods: get()?.neighborhoods,
        firstAccess: get()?.firstAccess,
        workerName: get()?.workerName,
        alertModal: get()?.alertModal,
        loginAs: get()?.loginAs,
        numberOfEstablishments: get()?.numberOfEstablishments,
        updateUserUuid: (userUuid: string) => set({ userUuid }),
        updateUserPhoto: (photo: string) => set({ photo }),
        updateUserName: (name: string) => set({ name }),
        updateGetList: (getList: boolean) => set({ getList }),
        updateEstablishment: (establishment: Establishment | null) =>
          set({ establishment }),
        updateEstablishmentGroup: (establishmentGroup: Establishment | null) =>
          set({ establishmentGroup }),
        updateNeighborhoods: (neighborhoods: Neighborhood[]) =>
          set({ neighborhoods }),
        updateFirstAccess: (firstAccess: boolean) => set({ firstAccess }),
        updateWorkerName: (workerName: string) => set({ workerName }),
        updateAlertModal: (alertModal: boolean) => set({ alertModal }),
        updateLoginAs: (loginAs: LoginAs) => set({ loginAs }),
        updateNumberOfEstablishments: (numberOfEstablishments: number) =>
          set({ numberOfEstablishments }),
        updateRole: (role: Worker | null) => set({ role }),
      }),
      persistConfig
    )
  )
);

if (global.window && (global.window as any).Cypress) {
  const initialState = (global.window as any).initialState;
  if (initialState) {
    useStore.setState(initialState);
  }
  (window as any).store = useStore;
}

export default useStore;
