type TranslateResponse = {
  [key: string]: string;
};

export const translateResponse: TranslateResponse = {
  'This email has already been registered': 'Este email já está registrado.',
  'This cpf has already been registered': 'Este CPF já foi cadastrado.',
  'This phone has already been registered':
    'Este número de telefone já foi cadastrado.',
  'phone must be a phone number': 'Número de telefone é inválido.',
  'Internal server error': 'Por favor, tente novamente!',
  'This Email already registred': 'Este e-mail já foi registrado.',
  'This subscription and change is for the same plan':
    'Esta assinatura e alteração é para o mesmo plano.',
  'This is not a subscription plan': 'Este não é um plano de assinatura.',
  'No Subscription found': 'Nenhuma assinatura encontrada.',
  'Not enough credits': 'Não há créditos suficientes.',
  'credit card not authorized, try another credit card':
    'Cartão de crédito inválido, tente outro.',
  'Change password instructions are send to your email':
    'As instruções foram enviadas para o seu e-mail.',
  'Incorrect Key': 'Código incorreto, tente novamente.',
  'Invalid Key': 'Código inválido, verifique seu e-mail',
  'Could not create credit card. The card verification failed.':
    'Não foi possível criar o cartão de crédito.',
  'No Coupon found': 'Cupom não encontrado!',
  'Coupon not found': 'Cupom não encontrado!',
  'Coupon is not valid yet': 'Cupom inválido!',
  'Coupon is expired': 'Cupom expirado!',
  'Coupon is not active': 'Cupom não está ativo!',
  'Coupon already used in current order': 'Você já usou esse cupom!',
  'Coupon already used and is single use': 'Você já usou esse cupom!',
  'Activity not available': 'Esse horário está lotado. Escolha outro!',
  'Time not found': 'Esse horário está lotado. Escolha outro!',
  'This Phone already registred': 'Este telefone já está registrado',
  "Already subscribed to a plan of type 'UNLIMITED'": 'Você já tem esse plano ',
  'This plan is only for new customers':
    'Esse plano é apenas para novos clientes',
  'This plan or subscription does not have credits':
    'Este plano ou assinatura não possui créditos',
  'Bad Request Exception': 'Ops! Falha ao processar.',
  'You exceded your unlimited plan for date':
    'Você excedeu seu plano ilimitado por dia',
  'You have more than 3 active checkins': 'Você tem mais de 3 check-ins ativos',
  'Product is more expensive than max credits':
    'O produto é mais caro que o máximo de créditos',
  'Invalid Plan': 'Plano inválido',
  'Product not found': 'Produto não encontrado',
  'This user not have credit card': 'Você não tem cartáo de crédito cadastrado',
  'credit card not authorized': 'Cartão de crédito inválido',
  'This version is not valid': 'Esta versão do app não é válida',
  'Email or Password are invalid': 'E-mail ou senha são inválidos',
  'Charge not paid': 'Cobrança não paga',
  'Failed to refund charge': 'Falha ao reembolsar a cobrança',
  'Checkin has invalid status for this process':
    'Check-in tem status inválido para este processo',
  'This checkin not have valid transaction':
    'Este check-in não tem transação válida',
  'Checkin has already been reviewed': 'O check-in já foi revisado',
  'Exceeds active checkins daily quota':
    'Excedeu a cota diária de check-ins ativos',
  'Invalid confirmation code': 'Código de confirmação inválido',
  'Coupon already used': 'Cupom já utilizado',
  'Coupon already user and is single use':
    'O cupom já é usuário e é de uso único',
  'Coupon already used max times': 'Cupom já usado no máximo de vezes',
  'Coupon type is invalid': 'O tipo de cupom é inválido',
  'This credit change have invalid type':
    'Esta alteração de crédito tem tipo inválido',
  'Credit already userd': 'Crédito já utilizado',
  'User not persisted': 'Usuário não persistido',
  'We cant set user on crm': 'Não podemos definir o usuário no crm',
  'We cant set client on crm': 'Não podemos definir o cliente no crm',
  'Dates provided are incosistent': 'As datas fornecidas são inconsistentes',
  'This DTO is invalid': 'Este DTO é inválido',
  'Establishment not published': 'Estabelecimento não publicado',
  'Establishment do not have phone': 'Estabelecimento não possui telefone',
  'Establishment do not have neighborhood': 'Estabelecimento não possui bairro',
  'Establishment do not have photos': 'Estabelecimento não possui fotos',
  'Establishment do not have modalities':
    'Estabelecimento não possui modalidades',
  'The establishment already have a product group with this name':
    'O estabelecimento já possui um grupo de produtos com este nome',
  'Forbidden resource': 'Recurso proibido',
  'Problems with server, plase try again later':
    'Problemas com o servidor, tente novamente mais tarde',
  'This invoice already paid': 'Esta fatura já foi paga',
  'Invoice invalid value': 'Valor inválido da fatura',
  'Invoice invalid status': 'Status inválido da fatura',
  'Already have modality with this name': 'Já tem modalidade com este nome',
  'Already have neighborhood with this name registred in this city':
    'Já possui bairro com este nome cadastrado nesta cidade',
  'Order does not have a subscription': 'O pedido não tem assinatura',
  'Order have invalid status for this operation':
    'O pedido tem status inválido para esta operação',
  'Order does not have invoice': 'Pedido não possui fatura',
  'Order has invalid type for this operation':
    'O pedido tem um tipo inválido para esta operação',
  'Invalid order item type': 'Tipo de item de pedido inválido',
  'This order item not have plan': 'Este item do pedido não tem plano',
  'This order item not have item': 'Este item do pedido não possui item',
  'This order item does not have transaction':
    'Este item do pedido não possui transação',
  'No payment method especified': 'Nenhuma forma de pagamento especificada',
  'Specified payment method is invalid':
    'A forma de pagamento especificada é inválida',
  'Debit note for this payment voucher has invalid status':
    'A nota de débito deste comprovante de pagamento tem status inválido',
  'This plan not have credits': 'Este plano não tem créditos',
  'Plan is invalid': 'O plano é inválido',
  'Plan coverage has been exceeded': 'A cobertura do plano foi excedida',
  'This plan can not be renewable': 'Este plano não pode ser renovável',
  'This plan type is invalid': 'Este tipo de plano é inválido',
  'This plan is not subscription plan':
    'Este plano não é um plano de assinatura',
  'This plan data is inconsitency with expected data':
    'Os dados deste plano são inconsistências com os dados esperados',
  'Product have active promotion': 'Produto tem promoção ativa',
  'Product not belong to establishment':
    'Produto não pertence ao estabelecimento',
  'Product already belongs to another product group':
    'O produto já pertence a outro grupo de produtos',
  'Invalid product hours': 'Horário de produto inválido',
  'This promotion already active': 'Esta promoção já está ativa',
  'This promotion already deactive': 'Esta promoção já está desativada',
  'Passwords not match': 'Senhas não coincidem',
  'Need more data to verify seu guru customer':
    'Precisa de mais dados para verificar seu cliente guru',
  'Subscription invalid status for this operation':
    'Status de assinatura inválida para esta operação',
  'Subscription is not active': 'A assinatura não está ativa',
  'Subscription change is for the same plan':
    'A mudança de assinatura é para o mesmo plano',
  'Subscription is not same as plan': 'A assinatura não é igual ao plano',
  'This subscription have wrong dates': 'Esta assinatura tem datas erradas',
  'This subscription not have next renew':
    'Esta assinatura não tem próxima renovação',
  'This transaction is invalid': 'Esta transação é inválida',
  'This user have invalid push token':
    'Este usuário tem um token de push inválido',
  'This email already registrod for user':
    'Este e-mail já foi registrado para o usuário',
  'This phone already registrod for user':
    'Este telefone já está registrado para o usuário',
  'This cpf already registrod for user':
    'Este CPF já está cadastrado para o usuário',
  'Web resource invalid type': 'Tipo inválido de recurso da Web',
  'Field can not be empty': 'O campo não pode ficar vazio',
  'Field must be valid email': 'O campo deve ser um e-mail válido',
  'Field must be string': 'O campo deve ser string',
  'Field must be boolean': 'O campo deve ser booleano',
  'Field must be datestring': 'O campo deve ser datastring',
  'Field must be object': 'O campo deve ser objeto',
  'Field must be uuid': 'O campo deve ser uuid',
  'Field must be int': 'O campo deve ser int',
  'Field number is lower than minimum':
    'O número do campo é inferior ao mínimo',
  'Field number is greather than maximum':
    'O número do campo é maior que o máximo',
  'Field value is not valid for enum options':
    'O valor do campo não é válido para opções de enum',
  'Field lenght is greater than max':
    'O comprimento do campo é maior que o máximo',
  'Field lenght is lower than min':
    'O comprimento do campo é inferior ao mínimo',
  'Field must be a date': 'O campo deve ser uma data',
  'Field must be a url': 'O campo deve ser um URL',
  'Field must be latitude': 'O campo precisa ser latitude',
  'Field must be longitude': 'O campo deve ser longitude',
  'Field must be number': 'O campo deve ser um número',
  'Field must be mobile phone': 'O campo deve ser celular',
  'Field must be array': 'O campo deve ser uma matriz',
  'Field array size is lower than min':
    'O tamanho da matriz de campo é menor que o mínimo',
  'Field array size is greater than max':
    'O tamanho da matriz de campo é maior que o máximo',
  'Field have invalid value': 'O campo tem valor inválido',
  'Field have nested attributes with invalid value':
    'O campo possui atributos aninhados com valor inválido',
  'amountOfCredits must be an integer number':
    'Valor do crédito deve ser um número inteiro',
  'Não há disponibilidade para esta aula':
    'Não há disponibilidade para esta aula',
  'Esta aula está indisponível por ser restrita':
    'Esta aula está indisponível por ser restrita',
  'Something wrong on create checkin on EVO':
    'Não há disponibilidade para esta aula',
  'website must be a URL address': 'O site deve ser um endereço URL',
  'This is not valid instagram': 'Campo Instagram inválido.',
};
