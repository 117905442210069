import React, { FC } from 'react';

import Clock from 'react-live-clock';

import { DigitalClockContainer } from './styles';

export const DigitalClock: FC = () => {
  return (
    <DigitalClockContainer>
      <Clock format={'HH:mm'} ticking={true} timezone={'America/Sao_Paulo'} />
    </DigitalClockContainer>
  );
};
