import React, { FC } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { CancellationReasonModalProps } from './types';

import {
  Container,
  ContentModal,
  Description,
  ContentTitle,
  Title,
  ButtonClose,
  Select,
  Input,
  SubmitButton,
} from './styles';
import useStore from '@/store';

export const CancellationReasonModal: FC<CancellationReasonModalProps> = ({
  open,
  onClose,
  onAction,
  checkinId,
}) => {
  const [fixedCancellationReason, setFixedCancellationReason] =
    React.useState('');
  const [cancellationReason, setCancellationReason] = React.useState('');

  const { updateGetList } = useStore(state => state);

  const handleClose = () => onClose && onClose(false);

  const handleAction = () => {
    if (onAction) {
      onAction(
        fixedCancellationReason === 'Outro motivo'
          ? cancellationReason
          : fixedCancellationReason,
        checkinId
      );
      onClose && onClose(false);
      updateGetList(false);
      setFixedCancellationReason('');
      setCancellationReason('');
    }
  };

  if (!open) {
    return null;
  }

  const disableButton =
    fixedCancellationReason === '' ||
    (fixedCancellationReason === 'Outro motivo' && cancellationReason === '');

  return (
    <Container onClick={handleClose}>
      <ContentModal onClick={e => e.stopPropagation()}>
        <ButtonClose onClick={handleClose}>
          <AiOutlineClose size={30} color="#818181" className="close" />
        </ButtonClose>
        <ContentTitle>
          <Title>
            <strong>
              Gostariamos de entender porque você quer cancelar essa reserva.
              AQUIII - {status}
            </strong>
          </Title>
        </ContentTitle>
        <Description>
          <Select
            id="cancelation-reason"
            name="cancelation-reason"
            onChange={e => setFixedCancellationReason(e.target.value)}>
            <option value="">Selecione o motivo do cancelamento...</option>
            <option>Problemas na academia</option>
            <option>Falta de horário</option>
            <option>Problemas com professores</option>
            <option>Check-in duplicado</option>
            <option>Aluno não vem/não apareceu</option>
            <option>Outro motivo</option>
          </Select>
          {fixedCancellationReason === 'Outro motivo' && (
            <Input
              type="text"
              placeholder="Digite o motivo..."
              onChange={e => setCancellationReason(e.target.value)}
            />
          )}
          <SubmitButton onClick={handleAction} $disabled={disableButton}>
            {disableButton ? 'Continuar sem enviar motivo' : 'Enviar motivo'}
          </SubmitButton>
        </Description>
      </ContentModal>
    </Container>
  );
};

export default CancellationReasonModal;
