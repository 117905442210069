import React from 'react';

const Logo = () => {
  return (
    <svg
      width="400"
      viewBox="0 0 300 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
        d="M8.95508 13.8646C8.46122 13.3356 7.67654 13.0715 6.60181 13.0715H3.4547C3.00512 13.0715 2.78452 12.8601 2.78452 12.4456C2.78452 12.225 2.85475 12.0403 3.00512 11.9082C3.15472 11.7762 3.27838 11.6968 3.37532 11.6701C3.93101 11.8815 4.46837 11.9876 4.98818 11.9876C6.15146 11.9876 7.08574 11.6525 7.79104 10.9824C8.99019 9.85419 8.83982 8.4436 8.83982 8.4436H6.81248C6.81248 8.46116 6.86515 9.21072 6.31862 9.67787C5.97514 9.96029 5.54311 10.1275 4.97902 10.1275C4.41494 10.1275 3.96535 9.97785 3.63942 9.67787C3.29594 9.35194 3.15472 8.46116 3.14556 8.4436H1.12662C1.12662 8.46116 1.09151 9.20157 1.4266 9.93358C1.57621 10.2595 1.86703 10.6564 2.06091 10.8595C1.29379 11.2999 0.915187 11.9701 0.915187 12.8601C0.915187 13.618 1.17929 14.1821 1.70826 14.5615C1.02052 15.0286 0.667877 15.6545 0.667877 16.4301C0.667877 17.2941 1.02052 17.946 1.71666 18.3956C2.41279 18.836 3.54172 19.0658 5.10191 19.0658C6.60944 19.0658 7.75516 18.7574 8.53068 18.149C9.3062 17.5407 9.69396 16.8094 9.69396 15.9538C9.69548 15.0813 9.44894 14.3936 8.95508 13.8646ZM5.05917 17.214C4.14244 17.214 3.50737 17.117 3.14633 16.9316C2.78528 16.7461 2.60896 16.4377 2.60896 16.0057C2.60896 15.5828 2.78528 15.2561 3.14633 15.0271H6.53998C6.90102 15.0271 7.17429 15.1065 7.37733 15.2653C7.57121 15.424 7.67731 15.653 7.67731 15.953C7.67731 16.7911 6.80409 17.214 5.05917 17.214Z"
        fill="#313A40"
      />
      <path
        d="M9.33137 3.4593C9.5886 3.91118 9.95652 4.69891 10.0122 5.24849C9.18329 4.59891 8.45281 3.87606 6.92925 3.64173C7.54524 2.99063 7.76965 2.49448 7.98261 2.01741C8.40014 2.15023 9.11612 3.0807 9.33137 3.4593ZM0.0465281 4.48442C0.376276 3.05704 2.34026 1.64416 3.17913 1.04115C3.92259 0.506072 4.84008 0 5.64919 0C5.89344 0 6.12702 0.0465609 6.3438 0.151897C5.43775 1.29685 2.44865 4.04017 0.232011 5.98126C0.00912615 5.42557 -0.0542282 4.92179 0.0465281 4.48442ZM1.21438 7.77731C0.766324 7.48039 0.611374 7.10561 0.468636 6.58579C2.90587 4.60883 5.45607 2.17008 6.77048 0.557976C6.95444 0.61446 7.36281 1.20679 7.41929 1.53882C6.92772 2.72653 6.0316 3.70661 5.03091 4.61112C5.02633 4.61494 5.02251 4.61876 5.01793 4.62257C4.5943 5.00423 3.34935 6.12018 2.8845 6.45069L1.21438 7.77731ZM3.36385 6.90257C3.5127 6.77738 4.3676 6.24841 4.5195 6.12247C4.89504 5.80875 5.33165 5.47519 5.41791 5.27138C5.95985 5.48206 6.87277 6.60412 7.27426 7.11858C5.9339 6.64457 4.24776 6.79341 3.36385 6.90257ZM8.47952 7.76358C8.47952 7.76358 8.32152 7.47275 8.01238 7.0911L7.65515 6.59801C7.56432 6.48657 7.47349 6.36978 7.38113 6.25147C6.98344 5.74005 6.54607 5.17979 5.9484 4.8279C6.16823 4.61341 6.38196 4.39053 6.58653 4.16078C7.29793 4.04017 9.15734 5.20727 9.72218 6.04003C9.69928 6.19956 9.74966 6.45069 9.56723 6.99722C9.36954 7.59412 8.47952 7.76358 8.47952 7.76358Z"
        fill="#313A40"
      />
      <path
        d="M15.9492 10.3611V5.76978H17.7766V13.7387H16.0874V12.7097C15.9034 13.0578 15.6034 13.3471 15.189 13.5776C14.7745 13.8081 14.2654 13.9234 13.6608 13.9234C12.7189 13.9234 11.9945 13.6395 11.4885 13.0716C10.9816 12.5037 10.7282 11.7434 10.7282 10.7916V5.76978H12.5556V10.545C12.5556 11.7533 13.0723 12.3571 14.1066 12.3571C14.6798 12.3571 15.1302 12.1861 15.4576 11.8426C15.7851 11.4999 15.9492 11.006 15.9492 10.3611Z"
        fill="#313A40"
      />
      <path
        d="M23.3823 5.70801V7.41247C23.2388 7.39187 23.1006 7.38194 22.9678 7.38194C21.5961 7.38194 20.9099 8.11929 20.9099 9.59324V13.7387H19.0826V5.76984H20.7718V6.87511C21.2221 6.08661 22.0053 5.69275 23.1212 5.69275L23.3823 5.70801Z"
        fill="#313A40"
      />
      <path
        d="M29.6467 10.3611V5.76978H31.474V13.7387H29.7849V12.7097C29.6009 13.0578 29.3009 13.3471 28.8864 13.5776C28.472 13.8081 27.9628 13.9234 27.3583 13.9234C26.4164 13.9234 25.692 13.6395 25.1859 13.0716C24.6791 12.5037 24.4257 11.7434 24.4257 10.7916V5.76978H26.253V10.545C26.253 11.7533 26.7698 12.3571 27.8041 12.3571C28.3773 12.3571 28.8277 12.1861 29.1551 11.8426C29.4826 11.4999 29.6467 11.006 29.6467 10.3611Z"
        fill="#313A40"
      />
      <path
        d="M35.4708 12.6945V16.9323H33.6435V5.76977H35.3327V6.90633C35.8952 6.02624 36.7913 5.58582 38.0195 5.58582C39.166 5.58582 40.0697 5.98502 40.73 6.78344C41.3902 7.58186 41.7208 8.57492 41.7208 9.76262C41.7208 10.9503 41.3902 11.9403 40.73 12.7342C40.0697 13.5272 39.166 13.9242 38.0195 13.9242C36.8524 13.9234 36.0028 13.5135 35.4708 12.6945ZM35.4097 9.76186C35.4097 10.4992 35.612 11.1083 36.0166 11.5892C36.4211 12.0709 36.9661 12.3105 37.6516 12.3105C38.3271 12.3105 38.8698 12.0678 39.2789 11.5808C39.6881 11.0946 39.8934 10.4885 39.8934 9.76109C39.8934 9.01382 39.6888 8.39478 39.2789 7.90321C38.869 7.41164 38.3271 7.16586 37.6516 7.16586C36.9654 7.16586 36.4204 7.40935 36.0166 7.89558C35.6113 8.38257 35.4097 9.00466 35.4097 9.76186Z"
        fill="#313A40"
      />
      <path
        d="M44.9907 13.9234C44.0487 13.9234 43.348 13.6929 42.887 13.2326C42.4267 12.7724 42.1962 12.1632 42.1962 11.4053C42.1962 10.4328 42.6259 9.75196 43.4862 9.36344C43.6907 9.27108 43.8877 9.20238 44.077 9.15582C44.2663 9.11002 44.6525 9.06346 45.2364 9.01767C45.8196 8.97187 46.5928 8.9482 47.5554 8.9482V8.549C47.5554 7.57654 46.9875 7.09032 45.8509 7.09032C44.8578 7.09032 44.2945 7.48418 44.1617 8.27268H42.3802C42.4618 7.49487 42.787 6.85217 43.3549 6.34533C43.9228 5.8385 44.7701 5.58508 45.8959 5.58508C47.0325 5.58508 47.8897 5.84613 48.4683 6.36823C49.0469 6.89033 49.3362 7.62234 49.3362 8.56426V13.7387H47.647V12.8945C47.0737 13.5807 46.1883 13.9234 44.9907 13.9234ZM47.5546 10.3298C45.773 10.3298 44.7296 10.4115 44.422 10.5756C44.1045 10.7397 43.9457 11.0358 43.9457 11.4663C43.9457 12.1526 44.4014 12.4953 45.312 12.4953C46.8066 12.4953 47.5538 11.9121 47.5538 10.745V10.3298H47.5546Z"
        fill="#313A40"
      />
      <path
        d="M53.4664 13.9234C52.3711 13.9234 51.5467 13.6677 50.9941 13.1555C50.4414 12.6441 50.1392 11.9884 50.088 11.19H51.8535C51.8741 11.5587 52.0322 11.8556 52.3298 12.0808C52.6268 12.306 53.0412 12.419 53.5733 12.419C54.0442 12.419 54.4076 12.3373 54.6633 12.1732C54.919 12.0091 55.0472 11.7892 55.0472 11.5129C55.0472 11.2061 54.8907 10.9778 54.5785 10.829C54.2663 10.6809 53.887 10.5595 53.442 10.4657C52.9962 10.3718 52.5459 10.2779 52.0909 10.1817C51.6352 10.0863 51.2513 9.86417 50.9399 9.5161C50.6277 9.16803 50.4712 8.6925 50.4712 8.08796C50.4712 7.39183 50.7605 6.80103 51.3391 6.3148C51.9177 5.82858 52.6825 5.58508 53.6343 5.58508C54.177 5.58508 54.6556 5.66981 55.0701 5.8385C55.4846 6.00719 55.8075 6.23007 56.0372 6.50639C56.267 6.78271 56.4418 7.06437 56.5593 7.35061C56.6769 7.63761 56.7463 7.92919 56.7669 8.22612H55.0174C54.9869 7.90858 54.851 7.63761 54.6106 7.41244C54.3702 7.18726 54.0297 7.07429 53.5893 7.07429C53.1489 7.07429 52.8115 7.15596 52.5756 7.31855C52.3398 7.4819 52.2222 7.70631 52.2222 7.99255C52.2222 8.2597 52.3802 8.46198 52.697 8.60014C53.0138 8.73829 53.3947 8.85661 53.8397 8.95584C54.2847 9.05507 54.7358 9.1543 55.1907 9.25582C55.6464 9.35657 56.0281 9.59243 56.3357 9.96187C56.6433 10.3313 56.7975 10.8229 56.7975 11.4358C56.7975 12.1526 56.5082 12.7464 55.9296 13.2174C55.3525 13.6876 54.5312 13.9234 53.4664 13.9234Z"
        fill="#313A40"
      />
      <path
        d="M60.6697 13.9234C59.5744 13.9234 58.75 13.6677 58.1974 13.1555C57.6448 12.6441 57.3425 11.9884 57.2914 11.19H59.0569C59.0775 11.5587 59.2355 11.8556 59.5332 12.0808C59.8301 12.306 60.2446 12.419 60.7766 12.419C61.2476 12.419 61.6109 12.3373 61.8666 12.1732C62.1223 12.0091 62.2505 11.7892 62.2505 11.5129C62.2505 11.2061 62.0941 10.9778 61.7819 10.829C61.4697 10.6809 61.0903 10.5595 60.6453 10.4657C60.1995 10.3718 59.7492 10.2779 59.2943 10.1817C58.8386 10.0863 58.4554 9.86417 58.1432 9.5161C57.831 9.16803 57.6745 8.6925 57.6745 8.08796C57.6745 7.39183 57.9638 6.80103 58.5424 6.3148C59.121 5.82858 59.8858 5.58508 60.8377 5.58508C61.3804 5.58508 61.859 5.66981 62.2734 5.8385C62.6879 6.00719 63.0108 6.23007 63.2406 6.50639C63.4703 6.78271 63.6451 7.06437 63.7626 7.35061C63.8802 7.63761 63.9497 7.92919 63.9703 8.22612H62.22C62.1895 7.90858 62.0536 7.63761 61.8132 7.41244C61.5727 7.18726 61.2323 7.07429 60.7919 7.07429C60.3514 7.07429 60.0141 7.15596 59.7782 7.31855C59.5423 7.4819 59.4248 7.70631 59.4248 7.99255C59.4248 8.2597 59.5828 8.46198 59.8996 8.60014C60.2163 8.73829 60.5972 8.85661 61.0422 8.95584C61.4872 9.05507 61.9383 9.1543 62.3933 9.25582C62.849 9.35657 63.2306 9.59243 63.5382 9.96187C63.8458 10.3313 64 10.8229 64 11.4358C64 12.1526 63.7107 12.7464 63.1322 13.2174C62.5559 13.6876 61.7338 13.9234 60.6697 13.9234Z"
        fill="#313A40"
      />
    </svg>
  );
};

export default Logo;
