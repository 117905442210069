import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  transition: width 0.3s;
  background-color: rgba(255, 255, 255, 0.8);
  height: 80px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 99;
  justify-content: space-between;
  display: none;

  @media screen and (max-width: 999px) {
    display: flex;
  }
`;

export const Logo = styled.div`
  margin-left: 28px;
`;

export const HamburgerButton = styled.div`
  display: flex;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 21px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 28px;

  span {
    width: 100%;
    height: 3px;
    background-color: #000;
    border-radius: 3px;
    transition: all 0.3s ease;
  }
`;

export const MobileMenu = styled.div`
  position: absolute;
  right: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  z-index: 100;
`;
