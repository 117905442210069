import styled from 'styled-components';

interface ButtonProps {
  isAccept?: boolean;
}

export const CardContainer = styled.div`
  background-color: #a1b622;
  border-radius: 16px;
  padding: 21px 35px 32px 35px;
  width: 45%;
  position: relative;
  min-height: 255px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;

  .userName {
    position: relative;
    display: inline-block;
    font-size: 1.5em;
    color: #000000;
    width: fit-content;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      bottom: -4px;
      height: 2px;
      background-color: #000000;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.4s ease-in-out;
    }
  }

  :hover {
    margin-left: 5px;
    margin-bottom: 5px;
    padding: 21px 35px 38px 40px;
    background-color: #9cae2f;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);

    .userName::after {
      transform: scaleX(1);
    }
  }

  @media screen and (max-width: 1368px) {
    height: auto;
    padding: 20px;
    min-height: auto;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  font-size: 24px;
  font-family: 'Graphik', sans-serif;
  font-weight: 500;
  line-height: 26px;
  color: #fff;
  margin-top: 21px;
  margin-bottom: 16px;
`;

export const Infos = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 44px;
  color: #fff;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    .dot {
      display: none;
    }
  }
`;

export const Activity = styled.div`
  font-size: 14px;
  font-family: 'Graphik', sans-serif;
  font-weight: 400;
  line-height: 15px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TimeInfo = styled.div`
  font-size: 14px;
  font-family: 'Graphik', sans-serif;
  font-weight: 500;
  line-height: 15px;
  color: #fff;
`;

export const Badge = styled.div`
  position: absolute;
  top: -10px;
  right: 20px;
  background-color: #e74c3c;
  color: #fff;
  padding: 8px 12px;
  border-radius: 16px;
  font-size: 10px;
  line-height: 15px;
  font-weight: bold;
  border: 4px solid #fff;
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Button = styled.button<ButtonProps>`
  width: 100%;
  padding: 9px 12px 9px 18px;
  height: 42px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  color: ${({ isAccept }) => (isAccept ? '#000' : '#fff')};
  background-color: ${({ isAccept }) => (isAccept ? '#E1FF2B' : '#FF392C')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 13px;
  font-family: 'Graphik', sans-serif;
  font-weight: 500;
  line-height: 15px;
  flex-wrap: nowrap;

  &:hover {
    opacity: 0.9;
  }
`;

export const Icon = styled.span`
  margin-left: 8px;
  font-size: 1.25rem;
`;
