import { useQueryClient } from '@tanstack/react-query';

export const useInvalidateCache = () => {
  const queryClient = useQueryClient();

  const invalidateQuery = (keyToInvalidate: string) => {
    queryClient.invalidateQueries({ queryKey: [keyToInvalidate] });
  };

  return {
    invalidateQuery,
  };
};

export default useInvalidateCache;
